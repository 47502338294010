import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

///PIPES
import { VisitCategoryPipe } from './pipes/visit-category.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

////SERVICES
import { UserService } from './services/user.service';
import { ServerService } from './services/server.service';

////COMPONENTS JQX WIDGETS
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel';
import { jqxWindowComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxwindow";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxInputComponent }    from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxinput';
import { jqxPasswordInputComponent }    from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpasswordinput';
import { jqxRadioButtonComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxradiobutton";
import { jqxCheckBoxComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxcheckbox";
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { jqxNumberInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxNumberInput";
import { jqxTreeComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtree";
import { jqxSplitterComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxsplitter";
import { jqxTooltipComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtooltip";
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxDropDownListComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist";
import { jqxCalendarComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxcalendar";
import { jqxChartComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxchart";
import { jqxEditorComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxeditor';

///COMPONENTS
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorComponent } from './common/error/error.component';
import { LoaderPopupComponent } from './common/loader-popup/loader-popup.component';
import { LoginPopupComponent } from './common/login-popup/login-popup.component';
///import { LLRoleSwitchComponent } from './app_LL/ll-role-switch/ll-role-switch.component';
import { LlMainCanvasComponent } from './app_LL/ll-main-canvas/ll-main-canvas.component';
import { LlUserInfoComponent } from './app_LL/ll-user-info/ll-user-info.component';
import { LlModelsSpComponent } from './app_LL/ll-models-sp/ll-models-sp.component';
import { LlAdminSpComponent } from './app_LL/ll-admin-sp/ll-admin-sp.component';
import { LlModelFunctionsSpComponent } from './app_LL/ll-model-functions-sp/ll-model-functions-sp.component';
import { LlCompanysSpComponent } from './app_LL/ll-companys-sp/ll-companys-sp.component';
import { LlContactsSpComponent } from './app_LL/ll-contacts-sp/ll-contacts-sp.component';
import { LlCompanyInfoComponent } from './app_LL/ll-company-info/ll-company-info.component';
import { LlCompanyModelsSpComponent } from './app_LL/ll-company-models-sp/ll-company-models-sp.component';
import { LlInfoRequestVisitsSpComponent } from './app_LL/ll-info-request-visits-sp/ll-info-request-visits-sp.component';
import { LlInfoRequestFormsSpComponent } from './app_LL/ll-info-request-forms-sp/ll-info-request-forms-sp.component';
import { LlInfoRequestEntryComponent } from './app_LL/ll-info-request-entry/ll-info-request-entry.component';
import { LlInfoRequestAddComponent } from './app_LL/ll-info-request-add/ll-info-request-add.component';
import { LlInfoRequestRemoveComponent } from './app_LL/ll-info-request-remove/ll-info-request-remove.component';
import { LlInfoRequestQuestionSwitchComponent } from './app_LL/ll-info-request-question-switch/ll-info-request-question-switch.component';

import { LlAdminGenericDgComponent } from './app_LL/ll-admin-generic-dg/ll-admin-generic-dg.component';
import { LlModelClassesSpComponent } from './app_LL/ll-model-classes-sp/ll-model-classes-sp.component';
import { LlCompanySwitchComponent } from './app_LL/ll-company-switch/ll-company-switch.component';
import { LlModelInfoComponent } from './app_LL/ll-model-info/ll-model-info.component';
import { LlModelDashComponent } from './app_LL/ll-model-dash/ll-model-dash.component';
import { LlModelReportsSpComponent } from './app_LL/ll-model-reports-sp/ll-model-reports-sp.component';
import { LlModelReportBbcOneComponent } from './app_LL/ll-model-report-bbc-one/ll-model-report-bbc-one.component';
import { LlModelPayersSpComponent } from './app_LL/ll-model-payers-sp/ll-model-payers-sp.component';
import { LlModelLocationsSpComponent } from './app_LL/ll-model-locations-sp/ll-model-locations-sp.component';
import { LlModelDisplaydataComponent } from './app_LL/ll-model-displaydata/ll-model-displaydata.component';
import { LlModelMetricsSpComponent } from './app_LL/ll-model-metrics-sp/ll-model-metrics-sp.component';
import { LlModelGraphOneComponent } from './app_LL/ll-model-graph-one/ll-model-graph-one.component';
import { LlModelMetricDisplayComponent } from './app_LL/ll-model-metric-display/ll-model-metric-display.component';
import { LlModelMetricGraphComponent } from './app_LL/ll-model-metric-graph/ll-model-metric-graph.component';
import { LlInfoRequestLisitingSpComponent } from './app_LL/ll-info-request-lisiting-sp/ll-info-request-lisiting-sp.component';
import { LlInfoRequestListingModelsSpComponent } from './app_LL/ll-info-request-listing-models-sp/ll-info-request-listing-models-sp.component';
///import { LlInfoRequestListingSwitchComponent } from './app_LL/ll-info-request-listing-switch/ll-info-request-listing-switch.component';
import { LlContactSwitchComponent } from './app_LL/ll-contact-switch/ll-contact-switch.component';
import { LlContactInfoComponent } from './app_LL/ll-contact-info/ll-contact-info.component';
import { LlInfoRequestEntryQuestionComponent } from './app_LL/ll-info-request-entry-question/ll-info-request-entry-question.component';
import { LlInfoRequestWidgetGeneralComponent } from './app_LL/ll-info-request-widget-general/ll-info-request-widget-general.component';
import { LlInfoRequestWidgetDocsComponent } from './app_LL/ll-info-request-widget-docs/ll-info-request-widget-docs.component';
import { LlInfoRequestWidgetLongTextComponent } from './app_LL/ll-info-request-widget-long-text/ll-info-request-widget-long-text.component';
import { LlInfoRequestWidgetMLPComponent } from './app_LL/ll-info-request-widget-mlp/ll-info-request-widget-mlp.component';
import { LlMessagePuComponent } from './app_LL/ll-message-pu/ll-message-pu.component';
///import { LlInfoRequestListingColgrpsSpComponent } from './app_LL/ll-info-request-listing-colgrps-sp/ll-info-request-listing-colgrps-sp.component';
import { LlAdminEmailRoutinesSpComponent } from './app_LL/ll-admin-email-routines-sp/ll-admin-email-routines-sp.component';
import { LlAdminEmailRoutinesEditComponent } from './app_LL/ll-admin-email-routines-edit/ll-admin-email-routines-edit.component';
import { LlAdminEmailTemplatesSpComponent } from './app_LL/ll-admin-email-templates-sp/ll-admin-email-templates-sp.component';
import { LlAdminEmailTemplateEditComponent } from './app_LL/ll-admin-email-template-edit/ll-admin-email-template-edit.component';
import { LlAdminNewslettersSpComponent } from './app_LL/ll-admin-newsletters-sp/ll-admin-newsletters-sp.component';
import { LlAdminNewsletterViewComponent } from './app_LL/ll-admin-newsletter-view/ll-admin-newsletter-view.component';
import { LlModelCmsCrosswalkComponent } from './app_LL/ll-model-cms-crosswalk/ll-model-cms-crosswalk.component';
import { LlModelCmsFunctionsSpComponent } from './app_LL/ll-model-cms-functions-sp/ll-model-cms-functions-sp.component';
import { LlModelPayerEditComponent } from './app_LL/ll-model-payer-edit/ll-model-payer-edit.component';
import { LlMainRoleDgComponent } from './app_LL/ll-main-role-dg/ll-main-role-dg.component';
import { LlAdminRoleCompaniesSpComponent } from './app_LL/ll-admin-role-companies-sp/ll-admin-role-companies-sp.component';
import { LlInfoRequestGraph01Component } from './app_LL/ll-info-request-graph01/ll-info-request-graph01.component';
import { LlInfoRequestDashboardComponent } from './app_LL/ll-info-request-dashboard/ll-info-request-dashboard.component';
import { LlInfoRequestDashboardGraphComponent } from './app_LL/ll-info-request-dashboard-graph/ll-info-request-dashboard-graph.component';
import { LlInfoRequestForumComponent } from './app_LL/ll-info-request-forum/ll-info-request-forum.component';
import { LlInfoRequestForumThreadComponent } from './app_LL/ll-info-request-forum-thread/ll-info-request-forum-thread.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HomeComponent,    
    jqxGridComponent,
    jqxPanelComponent,
    jqxWindowComponent,
    jqxTextAreaComponent,
    jqxInputComponent,
    jqxPasswordInputComponent,
    jqxRadioButtonComponent,
    jqxCheckBoxComponent,
    jqxDateTimeInputComponent,
    jqxNumberInputComponent,
    jqxTreeComponent,
    jqxSplitterComponent,
    jqxTooltipComponent,
    jqxPopoverComponent,
    jqxDropDownListComponent, 
    jqxCalendarComponent,  
    jqxChartComponent,
    jqxEditorComponent,   
    VisitCategoryPipe,
    SanitizeHtmlPipe,
    ErrorComponent,
    LoaderPopupComponent,
    LoginPopupComponent,
    ///LLRoleSwitchComponent,
    LlMainCanvasComponent,
    LlUserInfoComponent,
    LlModelsSpComponent,
    LlAdminSpComponent,
    LlModelFunctionsSpComponent,
    LlCompanysSpComponent,
    LlContactsSpComponent,
    LlCompanyInfoComponent,
    LlCompanyModelsSpComponent,
    LlInfoRequestVisitsSpComponent,
    LlInfoRequestFormsSpComponent,
    LlInfoRequestEntryComponent,
    LlInfoRequestAddComponent,
    LlInfoRequestRemoveComponent,
    LlInfoRequestQuestionSwitchComponent,
    LlAdminGenericDgComponent,
    LlModelClassesSpComponent,
    LlCompanySwitchComponent,
    LlModelInfoComponent,
    LlModelDashComponent,
    LlModelReportsSpComponent,
    LlModelReportBbcOneComponent,
    LlModelPayersSpComponent,
    LlModelLocationsSpComponent,
    LlModelDisplaydataComponent,
    LlModelMetricsSpComponent,
    LlModelGraphOneComponent,
    LlModelMetricDisplayComponent,
    LlModelMetricGraphComponent,   
    LlInfoRequestLisitingSpComponent,
    LlInfoRequestListingModelsSpComponent,
    ///LlInfoRequestListingSwitchComponent,
    LlContactSwitchComponent,
    LlContactInfoComponent,
    LlInfoRequestEntryQuestionComponent,
    LlInfoRequestWidgetGeneralComponent,
    LlInfoRequestWidgetDocsComponent,
    LlInfoRequestWidgetLongTextComponent,
    LlInfoRequestWidgetMLPComponent,
    LlMessagePuComponent,
    ///LlInfoRequestListingColgrpsSpComponent,
    LlAdminEmailRoutinesSpComponent,
    LlAdminEmailRoutinesEditComponent,
    LlAdminEmailTemplatesSpComponent,
    LlAdminEmailTemplateEditComponent,
    LlAdminNewslettersSpComponent,
    LlAdminNewsletterViewComponent,
    LlModelCmsCrosswalkComponent,
    LlModelCmsFunctionsSpComponent,
    LlModelPayerEditComponent,    
    LlMainRoleDgComponent,
    LlAdminRoleCompaniesSpComponent,
    LlInfoRequestGraph01Component,
    LlInfoRequestDashboardComponent,
    LlInfoRequestDashboardGraphComponent,
    LlInfoRequestForumComponent,
    LlInfoRequestForumThreadComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,

  ],
  providers: [
    UserService,
    ServerService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
