<div #mainDiv_ll_info_request_visits_sp>

  <div [hidden]="bundle.viewGraph || bundle.viewDash">

    <canvas #vertTab_ll_info_request_visits_sp></canvas>

    <!--scrollpane_ll_info_request_visits_sp-->
    <div *ngIf="bundle.showObj">

      <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
        {{bundle.lable}}
      </div>

      <jqxPanel #scrollpane_ll_info_request_visits_sp
                [style.top.px]="bundle.spY"
                style="background-color: transparent;
              border: none;
              outline: none;
              border-bottom: 1px solid  rgb(160, 158, 158);"
                [autoUpdate]="true"
                [rtl]="true"
                [width]="bundle.spW"
                [height]="bundle.spH">

        <tr *ngFor="let item of bundle.records" style="white-space: nowrap;">


          <img class="statusCircle" [src]="item.CIRCLEIMG_VISIT">

          <div class="spRowLabel"
               [id]="bundle.prefix + item.FOLDERID"
               title={{item.FOLDERNAME}}
               [style.width.px]="bundle.spW - 40" (click)="select($event)" style="float: left; padding-left:5px">
            {{item.FOLDERNAME}}
          </div>

        </tr>

      </jqxPanel>


      <!--BOTTOM-->
      <div id=bottomLine [style.top.px]="bundle.spB">

        <!--Add and Remove Btns-->
        <div id=add_ll_info_request_visits_sp *ngIf="bundle.canAdd" style="padding-top:10px;">

          <div class="wordBtnGroup">
            <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="add()">
            <div class="wordBtn" (click)="add()">Add</div>
          </div>

          <jqxTooltip style="float: left;"
                      [position]="'right'"
                      [name]="'movieTooltip'"
                      [autoHideDelay]="30000"
                      [content]="'Click to ADD an information request'"
                      [opacity]="1.0"
                      [width]="200">

            <img class="infoBtn_12" src="assets/infoBtn.png" />

          </jqxTooltip>

          <div class="wordBtnGroup" style="float: left; padding-left:10px">
            <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_remove.png" alt="" (click)="remove()">
            <div class="wordBtn" (click)="remove()">Remove</div>
          </div>

          <jqxTooltip style="float: left;"
                      [position]="'right'"
                      [name]="'movieTooltip'"
                      [autoHideDelay]="30000"
                      [content]="'Click to REMOVE an information request'"
                      [opacity]="1.0"
                      [width]="200">

            <img class="infoBtn_12" src="assets/infoBtn.png" />

          </jqxTooltip>
        </div>


        <div style="float: left; padding-top: 10px; padding-left: 5px;" >

          <!--View Graph Button-->
          <div *ngIf="bundle.canViewGraphs" style="float: left;" >

            <div class="wordBtnGroup">
              <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_chart_bar.png" alt="" (click)="selectGraph()">
              <div class="wordBtn" (click)="selectGraph()">Graph</div>
            </div>

            <!--<jqxTooltip style="float: left;"
                [position]="'right'"
                [name]="'movieTooltip'"
                [autoHideDelay]="30000"
                [content]="bundle.Tooltip01"
                [opacity]="1.0"
                [width]="200">

      <img class="infoBtn" src="assets/infoBtn.png" />

    </jqxTooltip>--->

          </div>

          <!--View Dash Button-->
          <div *ngIf="bundle.canViewDash" style="float: left; padding-left: 5px;">

            <div class="wordBtnGroup">
              <img class="btnIcon" height=18 style="width: auto;" src="assets/icon_ruler.png" alt="" (click)="selectDash()">
              <div class="wordBtn" (click)="selectDash()">Benchmark</div>
            </div>

          </div>

        </div>
      </div>

      <!--SEARCH-->
      <div id=search_ll_info_request_visits_sp *ngIf="bundle.canFilter"
           [style.top.px]="bundle.lblY - 0"
           [style.width.px]="bundle.spW"
           [style.left.px]="bundle.spW  - 10 "
           style="position: absolute;">

        <div class="wordBtnGroup">
          <div *ngIf="bundle.searchText  == '' ">
            <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
          </div>
          <div *ngIf="bundle.searchText  != '' ">
            <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
          </div>
        </div>

        <jqxPopover #popover_search
                    [offset]='{ left: -85, top: 0 }'
                    [arrowOffsetValue]="-0"
                    [width]="220"
                    [position]="'top'"
                    [title]="'Filter Categories'"
                    [selector]="'#search_ll_info_request_visits_sp'">

          <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">

            <div (keyup)="onKeyUp($event)" style="float: left; ">
              <jqxTextArea #text_search
                           [width]="140"
                           [height]="25"
                           [placeHolder]="'Type Search'">

              </jqxTextArea>
            </div>

            <div style="float: left; padding-left:10px; padding-top:0px; ">
              <div style="clear:left">
                <div class="wordBtn" (click)="search()">Search</div>
              </div>
              <div style="clear:left">
                <div class="wordBtn" (click)="clear()">Clear</div>
              </div>
            </div>
          </div>

        </jqxPopover>
      </div>

    </div>


    <!-- ll-info-request-forms-sp-->


    <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.selectedId > 1"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute;">

      <ll-info-request-forms-sp></ll-info-request-forms-sp>
    </div>

    <!-- ll-info-request-add-->
    <div *ngIf="bundle.addOrRemove == 'add'"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute;">

      <ll-info-request-add></ll-info-request-add>
    </div>

    <!-- ll-info-request-remove-->
    <div *ngIf="bundle.addOrRemove == 'remove'"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute;">

      <ll-info-request-remove></ll-info-request-remove>
    </div>

  </div>

  <div *ngIf="bundle.viewGraph || bundle.viewDash">

    <div [style.top.px]="10"
         [style.left.px]="10"
         [style.width.px]="100"
         [style.height.px]="25"
         style="position: absolute;z-index:100">

      <div class="wordBtnGroup">
        <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="goBack()">
        <div class="wordBtn14" style="padding-left:5px; padding-top:3px;" (click)="goBack()">Go Back</div>
      </div>

    </div>

    <div *ngIf="bundle.viewGraph"
         [style.top.px]="30"
         [style.left.px]="10"
         [style.width.px]="bundle.parentW"
         [style.height.px]="bundle.parentH - 60"
         style="position: absolute;">

      <ll-info-request-graph01></ll-info-request-graph01>
    </div>

    <div *ngIf="bundle.viewDash"
         [style.top.px]="30"
         [style.left.px]="10"
         [style.width.px]="bundle.parentW"
         [style.height.px]="bundle.parentH - 60"
         style="position: absolute;">

      <ll-info-request-dashboard></ll-info-request-dashboard>
    </div>

  </div>
</div>
