<div #mainDiv_ll_info_request_dashboard_graph>

  <div [style.height.px]="bundle.headerH" [style.width.px]="bundle.graphAreaW" style="float: left; padding-top: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">

    <div class="txtBold_14" style="float: left;" >
      {{bundle.QUESTIONNAME}}
    </div>

    <div style="float: left; padding-left: 10px;" >
      {{bundle.QUESTIONINSTRUCTIONS}}
    </div>

  </div>

  <div style="clear: left; padding-top: 10px;">

    <jqxChart #myChart_line
              [width]="bundle.graphAreaW"
              [height]="bundle.graphAreaH"
              [showLegend]="true"
              [enableAnimations]="true"
              [colorScheme]="'scheme04'"
              [enableCrosshairs]="true"
              [crosshairsColor]="'#111888'"
              [crosshairsLineWidth]="2"
              [showBorderLine]="false">
    </jqxChart>

  </div>

  <div [style.left.px]="bundle.graphAreaW - 115"
       [style.top.px]="bundle.graphAreaH + 15"
       [style.width.px]="110"
       [style.height.px]="13"
       style="position: absolute; background-color:white">
  </div>

</div>
