import { Component } from '@angular/core';

@Component({
  selector: 'll-info-request-forum',
  templateUrl: './ll-info-request-forum.component.html',
  styleUrls: ['../cssMain.css'],
})
export class LlInfoRequestForumComponent {

}
