import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'll-info-request-widget-docs',
  templateUrl: './ll-info-request-widget-docs.component.html',
  ////styleUrls: ['./ll-info-request-widget-docs.component.css']
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestWidgetDocsComponent {

  @ViewChild('metricHeader') metricHeader: ElementRef;
  @ViewChild('datagrid_link') private datagrid: jqxGridComponent;
  @ViewChild('popover_removeDocument') private popover_removeDocument: jqxPopoverComponent;

  constructor(
    private LlScrollpaneService: LlScrollpaneService,
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlPermissionsService: LlPermissionsService,
  ) { }


  bundle = {
    prefix: "",
    docDisplay: "",
    showObj: false,
    showFunction: false,
    dataEntry: false,
    canUpload: false,
    canDownload: false,
    canRemove: false,
    canEditDocs: false,
    acceptedExts: "",
    DESCRIPTION: "",
    LINKDOMAIN: "",
    ORIGIONALFILENAME: "",
    recordCount: 0,
    record: [],
    Docs: [],
    LlInfoRequestsService: this.LlInfoRequestsService,
    STUDYQUESTIONID: 0,
    STUDYUPLOADID: 0,
    objX: 0,
    objY: 0,
    objW: 0,
    objH: 20,
    functionX: 0,
    functionY: 0,
    functionW: 50,
    rowH: 22,
    boxW:0,
    colW01: 20,
    colW02: 70,
    colW03: 30,
    colW04: 0,
    padding_left: 80,
    initialLoad: false,
    READONLYYESNO: true,
    QUESTIONRESULT_USER: '',
    QUESTIONRESULT_SPONSOR: '',
    REMOVEREASON: 'User Removed',
    Tooltip01: '',
  }

  ngOnInit() {
  }

  //eventSubscription_renewQuestion: any;
  eventSubscription_changeDocDisplay: any;

  ngOnDestroy() {
    ///this.eventSubscription_renewQuestion.unsubscribe();
    this.eventSubscription_changeDocDisplay.unsubscribe();
  }

  ngAfterViewInit() {
    //this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

    //if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
    //this.setVars();
    //}

    //});

    this.eventSubscription_changeDocDisplay = this.LlInfoRequestsService.changeDocDisplay.subscribe(none => {
      this.setVars();
    });

    this.setVars();
  }

  setVars() {

    this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;

    this.bundle.showFunction = false;
    this.bundle.canUpload = false;
    this.bundle.canDownload = false;
    this.bundle.canRemove = false;




    setTimeout(() => {
      

      if (this.LlPermissionsService.check("upload")) {
        this.bundle.canUpload = true;
      }

      if (this.LlPermissionsService.check("download")) {
        this.bundle.canDownload = true;
      }

      if (this.LlPermissionsService.check("remove")) {
        this.bundle.canRemove = true;
        this.bundle.colW01 = 30;
      }

      if (this.LlPermissionsService.check("qcverify")
        || this.LlPermissionsService.check("qcreview")
        || this.LlPermissionsService.check("qclock")
        || this.LlPermissionsService.check("qcsignature")) {

        this.bundle.padding_left = 80

      }

      this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth 
      this.bundle.boxW = this.bundle.objW - this.bundle.padding_left - 80;


      this.bundle.colW04 = this.bundle.boxW - this.bundle.colW01 - this.bundle.colW02 - this.bundle.colW03 - 0

      this.getData(this.bundle);

    });


  }

  getData(bundle) {

    bundle.prefix = 'DX'
    let record: any = bundle.LlInfoRequestsService.getSelectStudyQuestion(bundle.STUDYQUESTIONID);

    ///console.log(record)

    setTimeout(() => {
      bundle.docDisplay = bundle.LlInfoRequestsService.getDocDisplay();
      bundle.functionY = 0;

      if (bundle.docDisplay == 'list') {
        bundle.functionY = 15;
      }

      bundle.showFunction = true;

      bundle.dataEntry = record.dataEntry;

      //questionStageId	questionStageName
      //1	Not Entered
      //2	Entered
      //3	Verified
      //5	Reviewed
      //6	Locked
      //20	Signed

      if (record.QUESTIONSTAGEID > 5) {
        bundle.dataEntry = false;
        bundle.canRemove = false;
        bundle.canUpload = false;
      }

     
           

      bundle.acceptedExts = bundle.LlInfoRequestsService.getacceptedExts()

    });

    let docRecords = bundle.LlInfoRequestsService.getQuestionUploads(bundle.STUDYQUESTIONID);

    bundle.recordCount = docRecords.length;

    bundle.Docs = docRecords;

    for (let i = 0; i < docRecords.length; i++) {
      docRecords[i].srcTxt = "data:image/jpg;base64," + docRecords[i].UPLOADTHUMBNAIL;
    }

    bundle.Tooltip01 = "FORMAT: Click the Line-Grid icon under the upload arrow to change document view format.  "

    if (bundle.canUpload == true) {
      bundle.Tooltip01 = bundle.Tooltip01 + "UPLOAD: Select this arrow pointing up to begin the process of uploading a file.  ";
    }
        
    if (bundle.canDownload == true) {
      bundle.Tooltip01 = bundle.Tooltip01 + " DOWNLOAD SINGLE: click the small blue arrow pointing down next to the document in the box.  ";
    }
    
    if (bundle.recordCount > 1 && bundle.canDownload == true) {
      bundle.Tooltip01 = bundle.Tooltip01 + " DOWNLOAD ALL: Click the arrow pointing down below this tooltip.";
    }

    if (bundle.canRemove == true) {
      bundle.Tooltip01 = bundle.Tooltip01 + " DELETE: Click the trash icon next to the document in the box.  ";
    }

    setTimeout(() => {
      bundle.objH = (docRecords.length * bundle.rowH) + 20;

      if (bundle.objH < 40) {
        bundle.objH = 40;
      }

      bundle.showObj = true;
      //this.highligh();
    });
  }

  highligh() {

    ///console.log("highlight");

    let doc = this.bundle.LlInfoRequestsService.getActiveStudyUpload();

    if (doc != null) {

      this.bundle.STUDYUPLOADID = doc.STUDYUPLOADID;

      setTimeout(() => {

        for (let i = 0; i < this.bundle.Docs.length; i++) {

          if (this.bundle.docDisplay == 'thumb') {
            if (this.bundle.Docs[i].STUDYUPLOADID != null && document.getElementById('BX' + this.bundle.Docs[i].STUDYUPLOADID.toString()) != null) {

              document.getElementById('BX' + this.bundle.Docs[i].STUDYUPLOADID.toString()).style.backgroundColor = '#e5e5e5';

              if (this.bundle.STUDYUPLOADID == this.bundle.Docs[i].STUDYUPLOADID) {
                document.getElementById('BX' + this.bundle.STUDYUPLOADID.toString()).style.backgroundColor = "blue";

              }
            }
          } else {

            if (this.bundle.Docs[i].STUDYUPLOADID != null && document.getElementById('ROW' + this.bundle.prefix + this.bundle.Docs[i].STUDYUPLOADID.toString()) != null) {

              document.getElementById('ROW' + this.bundle.prefix + this.bundle.Docs[i].STUDYUPLOADID.toString()).style.backgroundColor = "white";

              if (this.bundle.STUDYUPLOADID == this.bundle.Docs[i].STUDYUPLOADID) {
                document.getElementById('ROW' + this.bundle.prefix + this.bundle.STUDYUPLOADID.toString()).style.backgroundColor = "Aquamarine";

              }
            }
          }
        }
      });
    }

  }

  edit(event) {
    this.bundle.STUDYUPLOADID = this.LlScrollpaneService.get2alphaId(event)

    this.LlInfoRequestsService.setActiveStudyUploadId(this.bundle.STUDYUPLOADID);

    this.highligh();


    var thisBundle = {
      STUDYQUESTIONID: this.bundle.STUDYQUESTIONID,
      SUBJECTVISITFORMID: 0,
    }

    this.LlInfoRequestsService.setActiveQuestionTab(0); //view doc
    this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID);

  }

  download(event) {

    ///console.log("download")

    this.bundle.STUDYUPLOADID = this.LlScrollpaneService.get2alphaId(event);
    this.LlInfoRequestsService.setActiveStudyUploadId(this.bundle.STUDYUPLOADID);
    this.LlInfoRequestsService.NG_LL_infoReq_uploads_download();
  }

  downloadAll() {

    ///console.log("downloadAll")
    for (let i = 0; i < this.bundle.Docs.length; i++) {
     
      this.LlInfoRequestsService.setActiveStudyUploadId(this.bundle.Docs[i].STUDYUPLOADID);
      this.LlInfoRequestsService.NG_LL_infoReq_uploads_download();
    }
  }

  fileUpload(event) {

   // console.log("fileUpload")

    let fileList: FileList = event.target.files;
    this.LlInfoRequestsService.setActiveQuestionTab(0); //view doc
    this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID);


    if (fileList.length > 0) {

      let reader = new FileReader();
      let file: File = fileList[0];
      let value = ""

      reader.readAsDataURL(file);
      reader.onload = () => {


        value = String(reader.result).split(',')[1]

        this.LlInfoRequestsService.NG_LL_infoReq_uploads_add(this.getData, this.bundle, file, value)
      };
    }




  }

  confirmRemove(event) {

    this.popover_removeDocument.close()

    this.bundle.STUDYUPLOADID = this.LlScrollpaneService.get2alphaId(event);

    for (let i = 0; i < this.bundle.Docs.length; i++) {
      if (this.bundle.Docs[i].STUDYUPLOADID == this.bundle.STUDYUPLOADID) {

        this.bundle.ORIGIONALFILENAME = this.bundle.Docs[i].ORIGIONALFILENAME;

        setTimeout(() => {
          this.popover_removeDocument.open();
        }, 10);
      }
    }
  }

  remove(event) {

    this.popover_removeDocument.close();

    this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID);
    this.LlInfoRequestsService.setActiveStudyUploadId(this.bundle.STUDYUPLOADID);
    this.LlInfoRequestsService.NG_LL_infoReq_uploads_remove(this.getData, this.bundle.REMOVEREASON, this.bundle);
  }

  viewList() {
    this.LlInfoRequestsService.setDocDisplay("list");
  }

  viewThumbs() {
    this.LlInfoRequestsService.setDocDisplay("thumb");
  }
}


