<div #metricHeader>

  <div *ngIf="bundle.showObj" [style.width.px]="bundle.objW">
    <ll-info-request-entry-question id={{bundle.STUDYQUESTIONID}}></ll-info-request-entry-question>
  </div>

  <div [style.padding-left.px]="bundle.padding_left" style="clear: left; padding-top: 5px; ">

    <!--LIST display--->
    <div *ngIf="bundle.docDisplay == 'list'"
         style="float: left;">

      <div [style.padding-left.px]="bundle.colW01 + 0" style="float: left; font-weight: bold; font-size: 12px; font-family: Arial; ">

        <div [style.width.px]="bundle.colW02 - 0" style="float:left;padding-left:0px;">
          Date
        </div>

        <div [style.width.px]="bundle.colW03" style="float:left;padding-left:2px;">
          Int
        </div>

        <div [style.width.px]="bundle.colW04" style="float:left;padding-left:2px;">
          Document/File
        </div>
      </div>

    </div>

    <!--BOX-->
    <div [style.width.px]="bundle.boxW" style="float:left; white-space: nowrap; background-color:white; outline: none; border: 1px solid  rgb(160, 158, 158);">

      <div style="padding-top:0px; padding-bottom:10px">

        <div *ngFor="let item of bundle.Docs" style="float:left;">

          <!--THUMB-->
          <div *ngIf="bundle.docDisplay == 'thumb'">
            <div style="float:left; padding-left:5px; padding-bottom:5px">

              <div [style.width.px]="125" style="padding-left:3px; border: 1px solid lightgrey;">

                <div style="max-width: 125px; padding-left:0px; padding-top:0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
                  font-size: 10px; font-family: Arial; text-align: left; ">
                  {{item.FILENAME}}
                </div>
              </div>

              <div style="padding-top:5px;">
                <div style="float: left; width:10px; padding-top:5px; white-space: nowrap; ">

                  <div id=removeDoc_two *ngIf="bundle.canRemove" style="padding-left:1px; padding-top:0px; ">
                    <img class="icon"
                         [id]="'SP' + item.STUDYUPLOADID"
                         (click)="confirmRemove($event)"
                         height=12
                         style="width: auto; clear: left; padding-left:1px; cursor: pointer; "
                         src="assets/icon_trash.png" alt="">
                  </div>

                  <div *ngIf="bundle.canDownload" style="padding-left:1px; padding-top:5px; ">
                    <img class="icon"
                         [id]="'SP' + item.STUDYUPLOADID"
                         (click)="download($event)"
                         height=12
                         style="width: auto; clear: left; padding-left:2px; cursor: pointer; "
                         src="assets/icon_downArrow_solid.png" alt="">
                  </div>
                </div>

                <div style="float: left; padding-left:10px;">
                  <div style="border: 1px solid  rgb(160, 158, 158);">
                    <img class="thumb"
                         [id]="'SP' + item.STUDYUPLOADID"
                         (click)="edit($event)"
                         width=100
                         style="height: auto;"
                         src="{{item.srcTxt}}" />
                  </div>

                </div>

              </div>
            </div>
          </div>

          <!--LIST-->
          <div *ngIf="bundle.docDisplay == 'list'">

            <div [id]="'ROW' + bundle.prefix + item.STUDYUPLOADID"
                 [style.width.px]="bundle.objW"
                 [style.height.px]="bundle.rowH"
                 style="padding-top:0px; padding-bottom:4px; height:20px; font-weight: normal; font-size: 12px; font-family: Arial; white-space: nowrap;overflow: hidden; ">

              <div [style.width.px]="bundle.colW01" style="float: left; padding-top: 3px; white-space: nowrap; ">

                <div *ngIf="bundle.canRemove" style="float: left; padding-left: 4px; cursor: pointer; white-space: nowrap; ">
                  <img class="icon"
                       [id]="'SP' + item.STUDYUPLOADID"
                       (click)="confirmRemove($event)"
                       height=12
                       style="width: auto;"
                       src="assets/icon_trash.png">

                </div>

                <div *ngIf="bundle.canDownload" style="float: left; padding-left: 4px; cursor: pointer; white-space: nowrap; ">
                  <img class="icon"
                       [id]="'SP' + item.STUDYUPLOADID"
                       (click)="download($event)"
                       height=12
                       style="width: auto;"
                       src="assets/icon_downArrow_solid.png">
                </div>

              </div>

              <div [style.width.px]="bundle.colW02" [style.height.px]="bundle.rowH" style="float:left; padding-left:0px; white-space: nowrap; ">
                <div [id]="bundle.prefix + item.STUDYUPLOADID"  style="padding-top:3px">{{item.DOCUMENTDATE}}</div>
              </div>

              <div [style.width.px]="bundle.colW03" [style.height.px]="bundle.rowH" style="float:left; padding-left:0px; white-space: nowrap; ">
                <div [id]="bundle.prefix + item.STUDYUPLOADID"  style="padding-top:3px">{{item.AUTHORINT}}</div>
              </div>

              <div [style.width.px]="bundle.colW04 - 10" [style.height.px]="bundle.rowH" style="float: left; padding-left: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">
                <div [id]="bundle.prefix + item.STUDYUPLOADID"  style="padding-top:3px">{{item.FILENAME}}</div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <!--OPERATIONS-->
    <div style="float:left; padding-left:5px;">

      <div *ngIf="bundle.canUpload" class="upload-btn-wrapper" style="cursor: pointer; float:left; padding-left:0px; padding-top:0px; ">

        <button class="btn">
          <img id=btmImage height=18 style="width: auto; float:left; cursor: pointer;" src="assets/icon_upload.png" alt="">
        </button>

        <input type="file" (change)="fileUpload($event)" accept={{bundle.acceptedExts}}>
      </div>

      <!--tooltip-->
      <div *ngIf="bundle.canUpload" style="float: left; padding-top: 6px; padding-left: 6px; ">
        <jqxTooltip [position]="'left'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="bundle.Tooltip01"
                    [opacity]="1.0"
                    [width]="300">

          <img height=10 style="position:relative; top:-10px; width: auto; " src="assets/infoBtn.png" />

        </jqxTooltip>
      </div>

      <!--VIEW Selector-->
      <div class="view-thumbs-wrapper" style="cursor: pointer; clear:left; padding-left:0px; padding-top:0px; ">

        <div *ngIf="bundle.docDisplay == 'list'">
          <button class="btn" style="float:left;">
            <img class="icon"
                 id=btmImage
                 (click)="viewThumbs()"
                 height=17
                 style="width: auto; float:left; cursor: pointer; "
                 src="assets/icon_thumbs.png" alt="">
          </button>
        </div>

        <div *ngIf="bundle.docDisplay == 'thumb'">
          <button class="btn" style="float:left;">
            <img class="icon"
                 id=btmImage
                 (click)="viewList()"
                 height=17
                 style="width: auto; float:left; cursor: pointer; "
                 src="assets/icon_list.png" alt="">
          </button>
        </div>

      </div>

      <!--download button-->
      <div *ngIf="bundle.canDownload && bundle.recordCount > 1" style="cursor: pointer; float:left; padding-left:0px; padding-top:0px; ">

        <button class="btn">
          <img class="icon" id=btmImage height=18 style="width: auto; float:left; cursor: pointer;" (click)="downloadAll()" src="assets/icon_download.png" alt="">
        </button>

      </div>

    </div>

    <!--Remove PopOver-->
    <div id=removeDoc_one style="padding-top:0px;">
      <jqxPopover #popover_removeDocument
                  [offset]='{ left: 0, top: 0 }'
                  [arrowOffsetValue]="-170"
                  [width]="400"
                  [height]="140"
                  [position]="'top'"
                  [title]="'Delete Document'"
                  [showCloseButton]="true"
                  [selector]="'#removeDoc_one'">

        <div style="display: table;">
          <div class="txtNorm" width=100>Are you certain you want to delete this document? </div>
        </div>

        <div [style.width.px]="350" style="padding-left: 10px; padding-top: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis ">
          {{bundle.ORIGIONALFILENAME}}
        </div>

        <div class="wordBtnGroup_popover" style="padding-left: 0px; padding-top: 10px;">
          <div class="wordBtn" (click)="remove($event)">Yes, Delete</div>
        </div>

      </jqxPopover>
    </div>

  </div>

</div>

