import { Component } from '@angular/core';

@Component({
  selector: 'll-info-request-forum-thread',
  templateUrl: './ll-info-request-forum-thread.component.html',
  styleUrls: ['../cssMain.css'],
})
export class LlInfoRequestForumThreadComponent {

}
