import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlModelsService } from '../ll-models.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-model-functions-sp',
  templateUrl: './ll-model-functions-sp.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelFunctionsSpComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_functions_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_model_functions_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_model_functions_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') text_search: jqxTextAreaComponent;
 
  constructor(
    private LlScrollpaneService: LlScrollpaneService,
    private LlModelsService: LlModelsService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Categories",
    prefix: "DM",
    selectedId: -1,
    selectedId_ondeck: -1,
    selectedGROUP: '',
    selectedFOLDERNAME: '',
    showObj: true,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canView: false,
    canFilter: false,
    searchText: "",
    LlModelsService: this.LlModelsService,
    records: [],
    origionalRecords: [],
    groups: [],
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset: 0,
    spY: 0,
    spW: 100,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    Tooltip01: 'not Found',

  }

  ngOnInit() { }
  ngOnDestroy() { }

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.setVars();
  }

  setVars() {

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {

        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.getData();
      });
    });
  }

  getData() {

    var model = this.LlModelsService.getActiveCategory();

    ///console.log(model)

    this.bundle.records.splice(0)

    //if (model.FORLOANYESNO == "Yes") {
      if (model.ARDATAFEEDYESNO == "Yes" || model.LTCCMSSCORINGYESNO == "Yes") {
        ///this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Dashboard", FOLDERID:0, FOLDERTYPE: "dashboard" });
      }
    //}

    if (model.INFOREQUESTSYESNO == "Yes") {
      if (this.LlPermissionsService.check("questionDataEntry")
        || this.LlPermissionsService.check("download")
        || this.LlPermissionsService.check("upload")
        || this.LlPermissionsService.check("viewHidenQuestions")
        || this.LlPermissionsService.check("remove")


      ) {
        this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Info. Requests", FOLDERID: 1, FOLDERTYPE: "documents", });
      }

    }

    if (model.ARDATAFEEDYESNO == "Yes") {

      if (model.FORLOANYESNO == "Yes") {

        this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Reports (BBC)", FOLDERID: 2, FOLDERTYPE: "reports", REPORTFORMCONTEXTID: -99 });

        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Dashboard", FOLDERID: 3, FOLDERTYPE: "dashboard" });
        //this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Metrics", FOLDERID: 4, FOLDERTYPE: "metrics", REPORTFORMCONTEXTID: 78 });
        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Collect-Metrics", FOLDERID: 5, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Aging", FOLDERID: 6, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 76 });
        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Daily", FOLDERID: 7, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 73 });
        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Averages", FOLDERID: 8, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 74 });

        this.bundle.records.push({ GROUP: "Classes", FOLDERNAME: "Collect-Metrics", FOLDERID: 20, FOLDERTYPE: "metrics", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Classes", FOLDERNAME: "Aging", FOLDERID: 21, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 76 });
        this.bundle.records.push({ GROUP: "Classes", FOLDERNAME: "Daily", FOLDERID:22, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 73 });
        this.bundle.records.push({ GROUP: "Classes", FOLDERNAME: "Averages", FOLDERID: 23, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 74 });

        this.bundle.records.push({ GROUP: "Payers", FOLDERNAME: "Collect-Metrics", FOLDERID: 30, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Payers", FOLDERNAME: "Aging", FOLDERID: 31, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 76 });
        this.bundle.records.push({ GROUP: "Payers", FOLDERNAME: "Daily", FOLDERID: 32, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 73 });
        this.bundle.records.push({ GROUP: "Payers", FOLDERNAME: "Averages", FOLDERID: 33, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 74 });

        this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "Collect-Metrics", FOLDERID: 40, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "Aging", FOLDERID: 41, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 76 });
        this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "Daily", FOLDERID: 42, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 73 });
        this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "Averages", FOLDERID: 43, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 74 });

      } else {

        this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Reports", FOLDERID: 2, FOLDERTYPE: "reports", REPORTFORMCONTEXTID: -99 });

        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Collect-Metrics", FOLDERID: 5, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Model Total", FOLDERNAME: "Aging", FOLDERID: 6, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 75 });
        this.bundle.records.push({ GROUP: "Classes", FOLDERNAME: "Collect-Metrics", FOLDERID: 20, FOLDERTYPE: "metrics", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Classes", FOLDERNAME: "Aging", FOLDERID: 21, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 75 });
        this.bundle.records.push({ GROUP: "Payers", FOLDERNAME: "Collect-Metrics", FOLDERID: 30, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Payers", FOLDERNAME: "Aging", FOLDERID: 31, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 75 });
        this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "Collect-Metrics", FOLDERID: 40, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 72 });
        this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "Aging", FOLDERID: 41, FOLDERTYPE: "ardata", REPORTFORMCONTEXTID: 75 });


      }
    }

    if (model.LTCCMSSCORINGYESNO == "Yes") {
      //this.bundle.records.push({ GROUP: "Locations", FOLDERNAME: "CMS Scores", FOLDERID: 18, FOLDERTYPE: "cmsdata", REPORTFORMCONTEXTID: 67 });
    }

    this.bundle.selectedGROUP = this.bundle.records[0].GROUP;

    this.bundle.origionalRecords = this.bundle.records;
    this.bundle.groups.splice(0);

    for (let i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.groups.indexOf(this.bundle.records[i].GROUP) == -1) {
        this.bundle.groups.push(this.bundle.records[i].GROUP);
      }
    }

    let selectedId_initial = this.bundle.records[0].FOLDERID;

    setTimeout(() => {

      this.bundle.showObj = true;

      setTimeout(() => {

        if (selectedId_initial != null && document.getElementById(this.bundle.prefix + selectedId_initial.toString()) != null) {
          document.getElementById(this.bundle.prefix + selectedId_initial.toString()).click();
        }

      });
    });
  }

  select(event) {

    //console.log("select")

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.LlModelsService.setDiveLevel('View');
      
      this.bundle.showRemaining = false;
      

      setTimeout(() => {

        let newGroup = false;
        let newFolder = false;

        for (let i = 0; i < this.bundle.records.length; i++) {

          if (this.bundle.records[i].FOLDERID == this.bundle.selectedId_ondeck) {            

            if (this.bundle.selectedId != this.bundle.selectedId_ondeck) {
              this.LlModelsService.setActiveREPORTFORMCONTEXTID(this.bundle.records[i].REPORTFORMCONTEXTID, this.bundle.records[i].GROUP)
              this.bundle.selectedFOLDERNAME = this.bundle.records[i].FOLDERNAME.toLowerCase();
              newFolder = true;
            }

            if (this.bundle.records[i].GROUP.toLowerCase() != this.bundle.selectedGROUP.toLowerCase()) {              
              this.bundle.selectedGROUP = this.bundle.records[i].GROUP.toLowerCase();
              newGroup = true;
            }

            if (this.bundle.selectedId_ondeck >= 20) {
              
              if (newGroup == true) {
                //this.LlModelsService.clearAll_AR_VIEW_IDs()
              }

            }             
          }
        }        

        this.LlModelsService.setExportFileName(this.bundle.selectedGROUP + "_" + this.bundle.selectedFOLDERNAME);
        this.LlModelsService.setFunctionGroupName(this.bundle.selectedGROUP);

        if (newFolder == true && newGroup == false) {

          ////LlModelsService.selectGROUP_newFOLDERNAME emits for the selected group to change the folder/view
          this.LlModelsService.selectGROUP_newFOLDERNAME(this.bundle.selectedFOLDERNAME);
        }



        setTimeout(() => {
                    
          this.bundle.showRemaining = true;

          this.bundle.selectedId = this.bundle.selectedId_ondeck;  
          
        });

      });
    }
  }

  noAction() {
    //needed HTML buttons point to this 
  }

  edit(event) { }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        this.bundle.selectedId = this.bundle.records[0].CATID;

        //this.LlModelsService.setActiveId(this.bundle.selectedId, 'SP');
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].CATID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].CATID;
        ///this.LlModelsService.setActiveId(this.bundle.selectedId, 'SP');

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].CATID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  
}


