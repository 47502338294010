import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { stringToNumber } from "../utility";
import { removeNumberCommas } from "../utility";
import { javaDate_to_YYYYMMDD } from "../utility-date";


@Component({
  selector: 'll-model-displaydata',
  templateUrl: './ll-model-displaydata.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelDisplaydataComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_displayData') private mainDiv: ElementRef;
  @ViewChild('displayArea') public displayArea: jqxPanelComponent;
  @ViewChild('date_THROUGHDATE') date_THROUGHDATE: jqxDateTimeInputComponent;

  constructor(
    private LlModelsService: LlModelsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  bundle = {
    showDisplay: false,
    graphArea_show: false,
    displayArea_show: true,
    navigation_show: false,
    showChoices: true,   
    fromFunction:'',
    diveLevel: '',
    className: '',
    payerName: '',
    divisionName: '',
    regionName: '',
    stateName: '',
    locationName: '',
    functionGroupName:'',
    activeELEMENT: '',
    activeELEMENTLABEL:'',
    REPORTFORMCONTEXTID: 54,
    CLIENTFINANCIALCLASSID:0,
    LlModelsService: this.LlModelsService,
    LlScrollpaneService: this.LlScrollpaneService,
    date_THROUGHDATE: {},
    THROUGHDATE_NUM: '',
    ///THROUGHDATE_NUM_Origional: '',
    THROUGHDATE_DATE: '',
    daysBack: 0,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    graphAreaY: 0,
    graphAreaX: 0,
    graphAreaH: 150,

    choicesX: 0,
    choicesY: 0,
    choicesW: 110,
    choicesH: 0,

    displayAreaX: 0,
    displayAreaY: 0,    
    displayAreaW: 0,
    displayAreaH: 0,


    metricLabelsW: 225,
    dateColumnW: 75,
    showTab: true,
    horzTabsY: 10,
    orientationLblsY: 0,
    orientationLblsH: 30,


    hTabW: 110,
    hTabH: 25,
    hTabIndent: 0,
    hTabIndx: 0,
    horzTabs: [],
    canvasHT: {},
    ctxHT: {},
    activeHorzTab: "Averages",
    ///elements: [],
    forms: [],
    records: [],
    dates: [],
    allDates: [],
    dayChoices: [],
    dayChoice:'',
    columnChoices: [],
    columnChoice:'',
    payersTabIndex: -1,
    navArrowColW: 90,
    navAddressColW:0,

  }
   
  ngOnInit() { }

  eventSubscription_selectTab: any;

  ngOnDestroy() {
    this.eventSubscription_selectTab.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_selectTab = this.LlModelsService.LlModelDisplaydataComponent_tabSelect.subscribe(action => {
      //this.getData(this.bundle);
      this.setVars();

    });

    this.setDataVars();
  }

  setDataVars() {

    this.bundle.diveLevel = this.bundle.LlModelsService.getDiveLevel();
    this.bundle.diveLevel = this.bundle.diveLevel.toLowerCase();

    this.bundle.fromFunction = this.mainDiv.nativeElement.parentElement.id;

    this.bundle.dayChoices.push({ choice:'All Days', code:"All", SELECTED:true })
    this.bundle.dayChoices.push({ choice:'Business', code:"Business", SELECTED:false })
    //this.bundle.dayChoices.push({ choice:'Monthly', code:"Monthly", SELECTED:false })

    this.bundle.LlModelsService.setDAY_choice(this.bundle.dayChoices[0].code, true);
    this.setDayChoice();

    this.bundle.columnChoices.push({ choice:'30 Days', code:"30Days", SELECTED:true })
    this.bundle.columnChoices.push({ choice:'Month End', code:"MonthEnd", SELECTED:false })
    this.bundle.columnChoices.push({ choice:'Same Day', code:"SameDay", SELECTED:false })

    this.bundle.LlModelsService.setCOLUMN_choice(this.bundle.columnChoices[0].code, true); 
    this.setColumnChoice();

    this.setVars();
  }

  setVars() {
    //this.bundle.showDisplay = false;

    //console.log("setVars()")

    this.bundle.date_THROUGHDATE = this.date_THROUGHDATE;

    setTimeout(() => {

      if (this.LlPermissionsService.check("viewModelFilters")) {
        this.bundle.navigation_show = true;
      }

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth - 20;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
      this.bundle.remainingW = this.bundle.parentW;      
      this.bundle.orientationLblsY = this.bundle.horzTabsY + this.bundle.hTabH + 10;
      this.bundle.graphAreaY = this.bundle.orientationLblsY + this.bundle.orientationLblsH;

      if (this.bundle.navigation_show == false) {
        this.bundle.graphAreaY = 10;
      }

      this.bundle.choicesX = this.bundle.parentW - this.bundle.choicesW;      
      this.bundle.displayAreaY = this.bundle.graphAreaY + this.bundle.graphAreaH + 30;
      this.bundle.displayAreaW = this.bundle.choicesX;
      this.bundle.displayAreaH = this.bundle.parentH - this.bundle.displayAreaY - 40;
      this.bundle.choicesY = this.bundle.displayAreaY;
      this.bundle.choicesH = this.bundle.displayAreaH;

      this.bundle.navAddressColW = this.bundle.remainingW - this.bundle.navArrowColW;
      
      this.bundle.daysBack = Math.round(this.bundle.displayAreaW / 5);
      this.LlModelsService.setDaysBack(this.bundle.daysBack);
      
      setTimeout(() => {
        this.getData(this.bundle)
      });
    });

  }

  getData(bundle) {

    //console.log("getData(bundle)")

    bundle.showDisplay = false;
    bundle.graphArea_show = false;

    bundle.functionGroupName = bundle.LlModelsService.getFunctionGroupName();
    bundle.functionGroupName = bundle.functionGroupName.toLowerCase();

    console.log(bundle.functionGroupName)

    bundle.THROUGHDATE_NUM = bundle.LlModelsService.getThroughDate();

    let dateNumText = String(bundle.THROUGHDATE_NUM);
    var year = Number(dateNumText.substring(0, 4));
    var month = Number(dateNumText.substring(4, 6)) - 1;
    var day = Number(dateNumText.substring(6, 8));

    bundle.date_THROUGHDATE.setDate(new Date(year, month, day));
    bundle.date_THROUGHDATE.setMaxDate(new Date(year, month, day));

    bundle.LlModelsService.NG_LL_model_AR_displayData_dates_get('COLUMNS', done01, bundle);
    function done01(arr, bundle) {

      bundle.dates = arr;

      let colX = 0;

      for (let i = 0; i < bundle.dates.length; i++) {
        colX = (i * 90) + bundle.metricLabelsW;
        bundle.dates[i].colX = colX;
      }
            
      bundle.LlModelsService.NG_LL_model_AR_displayData_get('COLUMNS', done02, bundle);
    }

    function done02(arr, bundle) {

      bundle.records = arr;

      //console.log(arr)
      
      bundle.forms = bundle.LlModelsService.getForms();
         
        setTimeout(() => {
          
          if (bundle.diveLevel.toLowerCase() == 'view') {
            bundle.activeELEMENT = bundle.LlModelsService.getActiveElement();
            bundle.activeELEMENTLABEL = bundle.LlModelsService.getActiveElementLabel();

          } else { 
            bundle.activeELEMENT = bundle.records[0].ELEMENT;
          }

          bundle.payerName = bundle.LlModelsService.getPayerName();
          bundle.locationName = bundle.LlModelsService.getLocationName();
          bundle.className = bundle.LlModelsService.getPayerClassName();
          bundle.divisionName = bundle.LlModelsService.getDivisionName();
          bundle.regionName = bundle.LlModelsService.getRegionName();
          bundle.stateName = bundle.LlModelsService.getStateName();

          setTimeout(() => {

            bundle.showDisplay = true;
            bundle.graphArea_show = true;

            setTimeout(() => {
              if (document.getElementById(bundle.activeELEMENT.toString()) != null) {
                let element = document.getElementById(bundle.activeELEMENT.toString())
                element.className = 'rowHighlight_select'
              }
            });
          });
        });    

    }

  }

  selectThroughDate() {

    //console.log("selectThroughDate()")

    //this.bundle.showRemaining = false;

    let throughDate_date = String(this.date_THROUGHDATE.val())
    
    var myArray = throughDate_date.split("/");

    var day = myArray[1];

    if (Number(day) < 10) {
      day = "0" + day;
    }

    var month = myArray[0];

    if (Number(month) < 10) {
      month = "0" + month;
    }

    var year = myArray[2]

    let throughDate_num = year + "" + month + "" + day;

    //console.log("throughDate_num: " + throughDate_num)
    //console.log("this.bundle.THROUGHDATE_NUM: " + this.bundle.THROUGHDATE_NUM)

    if (throughDate_num != this.bundle.THROUGHDATE_NUM) { 

      let THROUGHDATE_MAX = this.LlModelsService.getThroughDate_MAX()

      if (Number(throughDate_num) > Number(THROUGHDATE_MAX)) {
        this.bundle.THROUGHDATE_NUM = THROUGHDATE_MAX;

      } else {

        this.bundle.THROUGHDATE_NUM = throughDate_num;
        this.LlModelsService.setThroughDate_num(throughDate_num)

      }

      let dateNumText = String(this.bundle.THROUGHDATE_NUM);
      var year2 = Number(dateNumText.substring(0, 4));
      var month2 = Number(dateNumText.substring(4, 6)) - 1;
      var day2 = Number(dateNumText.substring(6, 8));
      this.date_THROUGHDATE.setDate(new Date(year2, month2, day2));

      this.getData(this.bundle)

      //this.bundle.LlModelsService.setActiveElement_withElement(this.bundle.activeELEMENT);
    }
  }
  
  selectDive(event) {

    //console.log("selectDive(event)")

    this.bundle.diveLevel = event.toLowerCase();

    //console.log("this.bundle.diveLevel :" + this.bundle.diveLevel)

    this.bundle.LlModelsService.setDiveLevel(this.bundle.diveLevel);

    this.getData(this.bundle)
  }

  backToView() {

    //console.log("backToView()")

    this.bundle.diveLevel = 'view';
    this.bundle.LlModelsService.setDiveLevel('view');
    this.getData(this.bundle);
  }

  removeFilter(diveLevel) {

    //console.log("removeFilter(diveLevel)")
    
    if (diveLevel == 'class') {
      this.bundle.LlModelsService.setPayerClass(0);
      this.bundle.className = this.bundle.LlModelsService.getPayerClassName();
    } else if (diveLevel == 'payer') {
      this.bundle.LlModelsService.setPAYER(0);
      this.bundle.payerName = this.bundle.LlModelsService.getPayerName();
    } else if (diveLevel == 'location') {
      this.bundle.LlModelsService.setLOCATION(0);
      this.bundle.locationName = this.bundle.LlModelsService.getLocationName();
    } else if (diveLevel == 'division') {
      this.bundle.LlModelsService.setDIVISION(0);
      this.bundle.divisionName = this.bundle.LlModelsService.getDivisionName();
    } else if (diveLevel == 'region') {
      this.bundle.LlModelsService.setREGION(0);
      this.bundle.regionName = this.bundle.LlModelsService.getRegionName();
    } else if (diveLevel == 'state') {
      this.bundle.LlModelsService.setSTATE(0);
      this.bundle.stateName = this.bundle.LlModelsService.getStateName();
    }




    this.getData(this.bundle);
  }

  selectDiveElement(event, ELEMENT) {

    //console.log("selectDiveElement(event, ELEMENT)")

    if (event.args.checked == true) {
  
    
      this.setDiveLevels(this.bundle.diveLevel.toLowerCase(), ELEMENT)
      

      this.setActive(ELEMENT) 

    }
  }

  setDiveLevels(diveLevel, ID) {

    this.bundle.LlModelsService.setDiveLevel(diveLevel) 

    if (diveLevel == 'class') {
      this.bundle.LlModelsService.setPayerClass(ID);
      this.bundle.className = this.bundle.LlModelsService.getPayerClassName();

      //console.log("this.bundle.className: " + this.bundle.className)


    } else if (diveLevel == 'payer') {
      this.bundle.LlModelsService.setPAYER(ID);
      this.bundle.payerName = this.bundle.LlModelsService.getPayerName();
    } else if (diveLevel == 'location') {
      this.bundle.LlModelsService.setLOCATION(ID);
      this.bundle.locationName = this.bundle.LlModelsService.getLocationName();   
    } else if(diveLevel == 'division') {
      this.bundle.LlModelsService.setDIVISION(ID);
      this.bundle.divisionName = this.bundle.LlModelsService.getDivisionName();
    } else if (diveLevel == 'region') {
      this.bundle.LlModelsService.setREGION(ID);
      this.bundle.regionName = this.bundle.LlModelsService.getRegionName();
    } else if (diveLevel == 'state') {
      this.bundle.LlModelsService.setSTATE(ID);
      this.bundle.stateName = this.bundle.LlModelsService.getStateName();
    }
  }



  selectElement(event) {

    var target = event.target || event.srcElement || event.currentTarget;

    if (target != null) {
      var idAttr = target.id;
      this.bundle.activeELEMENT = idAttr;
    }

    this.setActive(this.bundle.activeELEMENT);
  }

  setActive(inboundELEMENT) {    

    this.bundle.activeELEMENT = inboundELEMENT;

    this.bundle.graphArea_show = false;

    for (let j = 0; j < this.bundle.records.length; j++) {
      if (document.getElementById(this.bundle.records[j].ELEMENT.toString()) != null) {
        let element = document.getElementById(this.bundle.records[j].ELEMENT.toString())
        element.className = 'rowHighlight_normal'
      }
    }

    for (let j = 0; j < this.bundle.records.length; j++) {

      if (document.getElementById(this.bundle.records[j].ELEMENT.toString()) != null) {

        let element = document.getElementById(this.bundle.records[j].ELEMENT.toString())

        if (this.bundle.records[j].ELEMENT == this.bundle.activeELEMENT) {

          element.className = 'rowHighlight_select'

          let diveLevel = this.bundle.LlModelsService.getDiveLevel();

          if (this.bundle.diveLevel.toLowerCase() == 'view') {
            this.bundle.activeELEMENTLABEL = this.bundle.records[j].LABEL;
            this.bundle.LlModelsService.setActiveElement_withElement(this.bundle.records[j].ELEMENT);

          } else if (diveLevel == 'class') {
            this.bundle.LlModelsService.setFINANCIALCLASSID_ACTIVE(this.bundle.activeELEMENT)

          } else if (diveLevel == 'payer') {
            this.bundle.LlModelsService.setPAYERID_ACTIVE(this.bundle.activeELEMENT)

          } else if (diveLevel == 'location') {
            this.bundle.LlModelsService.setLOCATIONID_ACTIVE(this.bundle.activeELEMENT)

          } else if (diveLevel == 'division') {
            this.bundle.LlModelsService.setDIVISIONID_ACTIVE(this.bundle.activeELEMENT)

          } else if (diveLevel == 'region') {
            this.bundle.LlModelsService.setREGIONID_ACTIVE(this.bundle.activeELEMENT)

          } else if (diveLevel == 'state') {
            this.bundle.LlModelsService.setSTATEID_ACTIVE(this.bundle.activeELEMENT)
          }


          setTimeout(() => {
            this.bundle.graphArea_show = true;
          });

        }
      }
    }
  }

  addEditAlarm(event, action) {

    var target = event.target || event.srcElement || event.currentTarget;

    ///console.log(action)

    if (target != null) {
      var idAttr = target.id;

      let activeELEMENT = idAttr.substr(2, idAttr.length - 2);

      this.setActive(activeELEMENT)

      //this.LlModelsService.trigerLoanAlarmPU(action);
    }
  }

  editAlarm(event, action) {
  }

  selectDayChoice(event) {

    let code = event.target.id;

    ///console.log(code)

    this.bundle.LlModelsService.setDAY_choice(code, false);
    this.setDayChoice()

    this.getData(this.bundle)
     
  }

  setDayChoice() {
    let code = this.bundle.LlModelsService.getDAY_choice();

    this.bundle.showChoices = false;

    for (var i = 0; i < this.bundle.dayChoices.length; i++) {
      this.bundle.dayChoices[i].SELECTED = false;

      if (code == this.bundle.dayChoices[i].code) {
        this.bundle.dayChoices[i].SELECTED = true;
      }
    }

    this.bundle.showChoices = true;

  }

  selectColumnChoice(event) {

    let code = event.target.id;
    this.bundle.LlModelsService.setCOLUMN_choice(code, false); 
    this.setColumnChoice();

    this.bundle.columnChoice = code
   
    this.getData(this.bundle)
  }

  setColumnChoice() {

    let code = this.bundle.LlModelsService.getCOLUMN_choice();

    this.bundle.showChoices = false;

    for (var i = 0; i < this.bundle.columnChoices.length; i++) {
      this.bundle.columnChoices[i].SELECTED = false;

      if (code == this.bundle.columnChoices[i].code) {
        this.bundle.columnChoices[i].SELECTED = true;
      }
    }

    this.bundle.showChoices = true;
  }

  downloadCSVReport() {
    this.LlModelsService.exportCSVReport(this.bundle, this.bundle.dates, this.bundle.forms, this.bundle.records);
  }

  downloadAllDatesCSVReport() {
    this.LlModelsService.NG_LL_model_AR_displayData_dates_get('All', done01, this.bundle);
    function done01(arr, bundle) {

      bundle.allDates = arr;

      bundle.LlModelsService.NG_LL_model_AR_displayData_get('All', done02, bundle);
    }

    function done02(records, bundle) {

      let forms = bundle.LlModelsService.getForms();

      bundle.LlModelsService.exportCSVReport(bundle, bundle.allDates, forms, records);
    }

  }

 
}

