import { ReturnStatement } from '@angular/compiler';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { LlRolesService } from './ll-roles.service';
import { LlPermissionsService } from './ll-permissions.service';
import { LlCompaniesService } from './ll-companies.service';
import { javaDate_to_YYYYMMDD } from "./utility-date";
import { stringToNumber } from "./utility";
import { removeNumberCommas } from "./utility";

@Directive()
@Injectable({
  providedIn: 'root'
})

export class LlModelsService {

  @Output() refreshPayerClass: EventEmitter<string> = new EventEmitter();
  @Output() ll_model_functions_sp_GROUP_newFOLDERNAME: EventEmitter<string> = new EventEmitter();
  @Output() ll_company_models_sp_mode: EventEmitter<string> = new EventEmitter();
  @Output() ll_admin_generic_dg_reflectChange: EventEmitter<any> = new EventEmitter();
  @Output() LlModelDisplaydataComponent_tabSelect: EventEmitter<any> = new EventEmitter();
  //@Output() ll_model_sp_reflectChange: EventEmitter<any> = new EventEmitter();

  constructor(public ServerService: ServerService,
    private UserService: UserService,
    private LlRolesService: LlRolesService,
    private LlPermissionsService: LlPermissionsService,
    private LlCompaniesService: LlCompaniesService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    SYSTEMROLEID: 0,
    CONTACTROLEID: 0,
    STUDYID_SYS: 0,
    CONTACTID: 0,
    COMPANYID: 0,
    COMPANYID_ACTIVE: 0,
    CLIENTID:0,
    REPORTOWNERCLIENTID: 0,
    LOANMODELID: 0,
    LOCATIONID: 0,
    PAYERID: 0,
    FINANCIALCLASSID: 0,
    DIVISIONID: 0,
    REGIONID: 0,
    STATEID: 0,
    LOCATIONID_ACTIVE: 0,
    PAYERID_ACTIVE: 0,
    FINANCIALCLASSID_ACTIVE: 0,
    DIVISIONID_ACTIVE: 0,
    REGIONID_ACTIVE: 0,
    STATEID_ACTIVE: 0,
    REPORTFORMCONTEXTID: 0,
    REPORTFORMCONTEXTGROUP:'',
    ACTION:'',
    NEWNAME: '',    
    daysBack:0,
    ELEMENT: '',
    ELEMENTID: 0,
    DIVE_ELEMENTID:0,
    CLIENTCOMPANYID: 0,
    SYSTEMOPERATIONREPORTSID:0,
    date_calc: '',
    date_now: '',
    THROUGHDATE_NUM: '',
    PAYER:[],
    MODEL: [],
    LOCATION:[],
    MODELNAME: '',
    SEARCHPARAMS: [],
    BBCELEMENTS: [],
    PROVIDER_SYSID: '',
    STATUS: 'A',
    STARTDATE_NUM: 0,
    ENDDATE_NUM: 0,
    DIVELEVEL: 'view',
    DAYCHOICE:'',
    COLUMNCHOICE: '',
    COLUMNS_OR_ALL:'',
  }

  bundle_local = {
    ServerService: this.ServerService,
    LlPermissionsService: this.LlPermissionsService,
    vars: this.vars,
    ELEMENTLABEL: '',
    FINANCIALCLASS_NAME: '',
    PAYER_NAME: '',
    LOCATION_NAME: '',
    REGION_NAME: '',
    DIVISION_NAME:'',
    STATE_NAME:'',
    THROUGHDATE_MAX: '',
    functionGroupName:'',
    refreshPayerClass: this.refreshPayerClass,
    ll_admin_generic_dg_reflectChange: this.ll_admin_generic_dg_reflectChange,
    exportFileName:'',
    modelRecords: [],
    inBundle: [],
    models: [],
    payerClasses: [],
    reports: [],
    forms: [],
    elements:[],
    metricGroups: [],
    locations: [],
    payers: [],
    displayData: [],
    activeModel: {},
  }

  NG_LL_user_models_get(callback01: Function, inBundle) {


    let dummyToTriggerCode = this.getThroughDate_num();

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_user_models_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    this.bundle_local.vars.STATUS = inBundle.STATUS;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      bundle_local.modelRecords.splice(0);

      if (contactRole.SYSTEMROLEID == 18
        || bundle_local.LlPermissionsService.check("addEditCompany")
        || bundle_local.LlPermissionsService.check("viewCompany")
        || bundle_local.LlPermissionsService.check("editUserRoles")
        || bundle_local.LlPermissionsService.check("viewUserInfo")
        || bundle_local.LlPermissionsService.check("addEditUser")
        || bundle_local.LlPermissionsService.check("editRolePermissions")
        || bundle_local.LlPermissionsService.check("viewRolePermissions")
        || bundle_local.LlPermissionsService.check("viewLogInRecords")
        || bundle_local.LlPermissionsService.check("adminEventTrail")
        || bundle_local.LlPermissionsService.check("viewEmailRoutines")
        || bundle_local.LlPermissionsService.check("editEmailRoutines")
        || bundle_local.LlPermissionsService.check("viewEmailRecords")       
        
      ) {
        bundle_local.modelRecords.push({ GROUP: "Other", FOLDERNAME: "Admin", FOLDERID: -999, COMPANYID:0 });
      }

      if (contactRole.SYSTEMROLEID == 18
        || bundle_local.LlPermissionsService.check("viewNewsLetters")
      ) {
        bundle_local.modelRecords.push({ GROUP: "Other", FOLDERNAME: "Newsletters", FOLDERID: -888, COMPANYID: 0  });
      }

      if (contactRole.SYSTEMROLEID == 18
        || bundle_local.LlPermissionsService.check("postingView")
        || bundle_local.LlPermissionsService.check("postingAdd")
      ) {
        bundle_local.modelRecords.push({ GROUP: "Other", FOLDERNAME: "Forum", FOLDERID: -777, COMPANYID: 0 });
      }

      if (arr.length > 0) {
        bundle_local.vars.LOANMODELID = arr[0].LOANMODELID;
        bundle_local.activeModel = arr[0];
      }

      ///add the server retrived array-modelRecords to the modelRecords
      for (let i = 0; i < arr.length; i++) {
        arr[i].GROUP = "Models";

        bundle_local.modelRecords[bundle_local.modelRecords.length] = arr[i];
      }

      callback01(bundle_local.modelRecords, inBundle);

    }
  }

  setActiveId(LOANMODELID, from?) {

    this.bundle_local.vars.LOANMODELID = LOANMODELID;

    this.clearAll_AR_VIEW_IDs();

    for (var i = 0; i < this.bundle_local.modelRecords.length; i++) {

      if (this.bundle_local.modelRecords[i].LOANMODELID == this.bundle_local.vars.LOANMODELID) {

        this.bundle_local.activeModel = this.bundle_local.modelRecords[i]

        ///this.NG_LL_model_AR_retreiveThroughDate();

      }
    }

    //console.log(this.bundle_local.vars.LOANMODELID)
    //console.log(this.bundle_local.activeModel)

    if (from == "ll_model_sp") {
      this.NG_LL_model_AR_retreiveThroughDate();
    }
    

  }

  getActiveId() {
    return this.bundle_local.vars.LOANMODELID
  }

  getActiveCategory(callback01?, bundle?) {

    //console.log("getActiveCategory: "+this.activeFOLDERID)

    for (let i = 0; i < this.bundle_local.modelRecords.length; i++) {
      if (this.bundle_local.modelRecords[i].FOLDERID == this.bundle_local.vars.LOANMODELID) {

        if (callback01 != null) {
          callback01(this.bundle_local.modelRecords[i], bundle)
        } else {
          return this.bundle_local.modelRecords[i];
        }
      }
    }

  }

  NG_LL_company_models_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();
    //var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_company_models_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId()
    

    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (arr.length > 0 && bundle_local.vars.LOANMODELID < 1) {
        bundle_local.vars.LOANMODELID = arr[0].LOANMODELID;
      }

      bundle_local.models = arr;

      callback01(bundle_local.models, inBundle)

    }
  }

  NG_LL_company_model_add( MODELNAME, callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_company_model_add";
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();
    this.bundle_local.vars.MODELNAME = MODELNAME;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      if (arr.length > 0) {
        bundle_local.vars.LOANMODELID = arr[0].LOANMODELID_NEW;
      }

      bundle_local.models = arr;

      callback01(inBundle)

    }
  }

  getExistingCompanyModels(callback01: Function, inBundle) {
    callback01(this.bundle_local.models, inBundle)
  }

  getActiveLoanModel(callback01?: Function, inBundle?) {

    //for (var i = 0; i < this.bundle_local.models.length; i++) {
      //if (this.bundle_local.models[i].LOANMODELID == this.bundle_local.vars.LOANMODELID) {
                
        if (callback01 == null) {
          return this.bundle_local.activeModel;/// this.bundle_local.models[i]
        } else {
          //callback01(this.bundle_local.models[i], inBundle)

          callback01(this.bundle_local.activeModel, inBundle)
        }
      //}
    //}

    

  }

  NG_LL_model_AR_retreiveThroughDate(callback01?: Function, inBundle?) {

    ///console.log("NG_LL_model_AR_retreiveThroughDate()")

    this.bundle_local.vars.ac = "NG_LL_model_AR_retreiveThroughDate";

    var model = this.getActiveCategory();    

    if (model.ARDATAFEEDYESNO == "Yes") {
      this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "hide");
    }
    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (arr.length > 0) {

        if (arr[0].PERIODENDDATE_NUM != '' && arr[0].PERIODENDDATE_NUM != null) {
          bundle_local.vars.THROUGHDATE_NUM = arr[0].PERIODENDDATE_NUM;
          bundle_local.THROUGHDATE_MAX = arr[0].PERIODENDDATE_NUM; 
        }        
      }

      ///console.log(bundle_local.vars.THROUGHDATE_NUM)

      if (callback01 != null) {
        callback01(bundle_local.vars.THROUGHDATE_NUM, inBundle)
      }
    }    
  }

  setThroughDate_num(newThroughDate_num) {

    ///console.log("setThroughDate_num(newThroughDate_num) " + newThroughDate_num)

    this.bundle_local.vars.THROUGHDATE_NUM = newThroughDate_num;
  }

  getThroughDate_num() {
    return this.bundle_local.vars.THROUGHDATE_NUM;
  }

  getThroughDate_MAX() {
    return this.bundle_local.THROUGHDATE_MAX;
  }

  getThroughDate() {
    return this.bundle_local.vars.THROUGHDATE_NUM;
  }

  setDaysBack(daysBack) {
    this.bundle_local.vars.daysBack = daysBack;
  }

  clearAll_AR_VIEW_IDs() {
    this.setLOCATION(0)
    this.setPAYER(0)
    this.setPayerClass(0)
    this.setDIVISION(0)
    this.setREGION(0)
    this.setSTATE(0)
  }

  NG_LL_company_model_get(callback01: Function, inBundle ) {

    this.bundle_local.vars.ac = "NG_LL_company_model_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)
      callback01(arr, inBundle)

    }
  }

  NG_LL_company_model_edit(MODEL, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_company_model_edit";
    this.bundle_local.vars.MODEL = MODEL;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {


    }

  }

  NG_LL_model_AR_payerClasses_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      bundle_local.payerClasses = arr;

      if (arr.length > 0) {
        bundle_local.vars.FINANCIALCLASSID = arr[0].CLIENTFINANCIALCLASSID
        bundle_local.FINANCIALCLASS_NAME = arr[0].FOLDERNAME
      }

      callback01(arr, inBundle)
      
    }
  }

  getExistingPayerClasses(callback01: Function, inBundle) {
    callback01(this.bundle_local.payerClasses, inBundle);
  }

  setFINANCIALCLASSID(FINANCIALCLASSID) {
    this.bundle_local.vars.FINANCIALCLASSID = FINANCIALCLASSID;

    for (var i = 0; i < this.bundle_local.payerClasses.length; i++) {
      if (this.bundle_local.payerClasses[i].CLIENTFINANCIALCLASSID == this.bundle_local.vars.FINANCIALCLASSID) {
        this.bundle_local.FINANCIALCLASS_NAME = this.bundle_local.payerClasses[i].FOLDERNAME
      }
    }
  }

  setFINANCIALCLASSID_ACTIVE(FINANCIALCLASSID) {
    this.bundle_local.vars.FINANCIALCLASSID_ACTIVE = FINANCIALCLASSID;
  }

  setPayerClass(FINANCIALCLASSID) {
    this.bundle_local.vars.FINANCIALCLASSID = FINANCIALCLASSID;

    this.bundle_local.FINANCIALCLASS_NAME = "";

    for (let i = 0; i < this.bundle_local.displayData.length; i++) {
      if (this.bundle_local.displayData[i].ELEMENT == FINANCIALCLASSID) {
        this.bundle_local.FINANCIALCLASS_NAME = this.bundle_local.displayData[i].LABEL
        this.setPAYER(0)
      }
    }    
  }

  getPayerClassName() { return this.bundle_local.FINANCIALCLASS_NAME }

  getPayerClassId() {
    return this.bundle_local.vars.FINANCIALCLASSID;
  }

  NG_LL_model_AR_payerClasses_addEdit(ACTION, NEWNAME, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_addEdit";
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.NEWNAME = NEWNAME;    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      if (ACTION == 'ADD') {        
        bundle_local.vars.FINANCIALCLASSID = arr[0].NEW_CLIENTFINANCIALCLASSID
      }

      bundle_local.payerClasses = arr;
      bundle_local.refreshPayerClass.emit();
    }
  }

  NG_LL_model_AR_payerClasses_picklist_get(callback01, inBundle, obj) {
      
    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_picklist_get";    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)    

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
        } else {
          arr[i].SELECTED = false;
        }
      }
            
      callback01(arr, inBundle, obj)
     
    }


  }

  NG_LL_model_AR_payerClasses_picklist_edit(ACTION, PAYERID) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payerClasses_picklist_edit";    
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.PAYERID = PAYERID;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_dashboard_get(callback01?, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_dashboard_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.dashboardElements = arr;

      bundle_local.activeDashboardELEMENT = arr[0].PREFERENCEELEMENT;

      callback01(arr, inBundle);
    }
  }

  NG_LL_model_AR_reports_list_get(callback01: Function, ACTION, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_list_get";
    this.bundle_local.vars.ACTION = ACTION;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.reports = arr;

      if (arr.length > 0) {
        ///bundle_local.vars.THROUGHDATE_NUM = arr[0].THROUGHDATE_NUM     
        bundle_local.vars.SYSTEMOPERATIONREPORTSID = arr[0].SYSTEMOPERATIONREPORTSID;
      } else {
        bundle_local.vars.SYSTEMOPERATIONREPORTSID = 0;
      }

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }
    }
  }

  setActiveSYSTEMOPERATIONREPORTSID(SYSTEMOPERATIONREPORTSID) {
    this.bundle_local.vars.SYSTEMOPERATIONREPORTSID = SYSTEMOPERATIONREPORTSID;
  }

  getActiveSYSTEMOPERATIONREPORTSID() {
    return this.bundle_local.vars.SYSTEMOPERATIONREPORTSID;
  }

  getActiveReport(callback01?: Function, inBundle?) {

    for (var i = 0; i < this.bundle_local.reports.length; i++) {
      if (this.bundle_local.reports[i].SYSTEMOPERATIONREPORTSID == this.bundle_local.vars.SYSTEMOPERATIONREPORTSID) {

        if (callback01 == null) {
          return this.bundle_local.reports[i];
        } else {
          callback01(this.bundle_local.reports[i], inBundle)
        }

      }

    }

  }

  NG_LL_model_AR_reports_grid_elements_get(callback01: Function, inBundle, obj?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_grid_elements_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }

  getReportData(callback01?: Function, inBundle?, obj?) {

    var report = this.getActiveReport();

    this.bundle_local.vars.ac = report.OPSREPORTCOMPONENT;    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      if (callback01 != null && obj != null) {
        callback01(arr, inBundle, obj)
      } else if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }
    }
  }

  setREPORTFORMCONTEXTID(REPORTFORMCONTEXTID) {
    this.bundle_local.vars.REPORTFORMCONTEXTID = REPORTFORMCONTEXTID;
  }

  setActiveREPORTFORMCONTEXTID(REPORTFORMCONTEXTID, REPORTFORMCONTEXTGROUP) {

    this.bundle_local.vars.REPORTFORMCONTEXTID = REPORTFORMCONTEXTID
    this.bundle_local.vars.REPORTFORMCONTEXTGROUP = REPORTFORMCONTEXTGROUP

    //console.log("REPORTFORMCONTEXTID: " + this.bundle_local.vars.REPORTFORMCONTEXTID)
    //console.log("REPORTFORMCONTEXTGROUP: " + this.bundle_local.vars.REPORTFORMCONTEXTGROUP)

    if (this.bundle_local.vars.REPORTFORMCONTEXTID != -99) {
      //var n = new Date();

      //this.setThroughDate_num(javaDate_to_YYYYMMDD(n));
    }

    ////set the active ELEMENT to null so the new default will be the top element in the view. see: NG_LL_model_AR_formElements_get
    this.bundle_local.vars.ELEMENT = "";

    //set active SYSTEMOPERATIONREPORTSID  to zero of server-side NG_LL_model_AR_formElements_get toggle code.
    this.bundle_local.vars.SYSTEMOPERATIONREPORTSID = 0;

    ///does not equal metrics (55)
    if (REPORTFORMCONTEXTGROUP.toLowerCase() == 'classes' && REPORTFORMCONTEXTID != 55) {
      this.bundle_local.vars.PAYERID = 0;
      this.bundle_local.vars.LOCATIONID = 0

    } else if (REPORTFORMCONTEXTGROUP.toLowerCase() == 'payers' && REPORTFORMCONTEXTID != 55) {

      this.bundle_local.vars.FINANCIALCLASSID = 0;
      this.bundle_local.vars.LOCATIONID = 0

    } else if (REPORTFORMCONTEXTGROUP.toLowerCase() == 'locations' && REPORTFORMCONTEXTID != 55) {

      this.bundle_local.vars.FINANCIALCLASSID = 0;
      this.bundle_local.vars.PAYERID = 0;

    } else {

      this.bundle_local.vars.PAYERID = 0;
      this.bundle_local.vars.LOCATIONID = 0
      this.bundle_local.vars.FINANCIALCLASSID = 0;
    }

    //console.log("PAYERID: " + this.bundle_local.vars.PAYERID)
    //console.log("LOCATIONID: " + this.bundle_local.vars.LOCATIONID)
    //console.log("FINANCIALCLASSID: " + this.bundle_local.vars.FINANCIALCLASSID)
  }

  getActiveREPORTFORMCONTEXTID() {
    return this.bundle_local.vars.REPORTFORMCONTEXTID;
  }

  selectGROUP_newFOLDERNAME(selectedFOLDERNAME) {

    //console.log("selectGROUP_newFOLDERNAME")


    this.ll_model_functions_sp_GROUP_newFOLDERNAME.emit(selectedFOLDERNAME);
  }

  NG_LL_model_AR_locations_get(callback01: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_locations_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.locations = arr;

      if (arr.length > 0) {
        bundle_local.vars.LOCATIONID = arr[0].LOCATIONID
        bundle_local.LOCATION_NAME = arr[0].FOLDERNAME
      }

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }

    }
  }

  setLOCATIONID(LOCATIONID) {
    this.bundle_local.vars.LOCATIONID = LOCATIONID;

    for (var i = 0; i < this.bundle_local.locations.length; i++) {
      if (this.bundle_local.locations[i].LOCATIONID == this.bundle_local.vars.LOCATIONID) {
        this.bundle_local.LOCATION_NAME = this.bundle_local.locations[i].FOLDERNAME
      }
    }
  }

  setLOCATIONID_ACTIVE(LOCATIONID) {
    this.bundle_local.vars.LOCATIONID_ACTIVE = LOCATIONID;
  }

  setLOCATION(LOCATIONID) {
    this.bundle_local.vars.LOCATIONID = LOCATIONID;

    this.bundle_local.LOCATION_NAME = ""

    for (let i = 0; i < this.bundle_local.displayData.length; i++) {
      if (this.bundle_local.displayData[i].ELEMENT == LOCATIONID) {
        this.bundle_local.LOCATION_NAME = this.bundle_local.displayData[i].LABEL
      }
    }
} 

  getLocationName() { return this.bundle_local.LOCATION_NAME }

  getLOCATIONID() {
    return this.bundle_local.vars.LOCATIONID;
  }

  NG_LL_model_AR_location_target_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_target_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      // console.log(arr)

      callback01(arr, inBundle)

    }
  }

  NG_LL_model_AR_location_target_edit(LOCATION, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_target_edit";
    this.bundle_local.vars.LOCATION = LOCATION;

    console.log(this.bundle_local.vars.LOCATION)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      //PAYER[0].TRIGGER = 'payer'

      //bundle_local.ll_admin_generic_dg_reflectChange.emit(PAYER);

    }
  }

  location_CMS_search_mode(LOCATIONID) {
    this.setLOCATIONID(LOCATIONID)
    this.ll_company_models_sp_mode.emit('findCMS')
  }

  NG_LL_model_AR_location_CMS_search(callback01: Function, searchParams, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_CMS_search";

    this.bundle_local.vars.SEARCHPARAMS = searchParams;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      callback01(arr, inBundle, obj);
    }
  }

  NG_LL_model_AR_location_CMS_edit(LOCATION_CMS,inBundle) {

    this.bundle_local.inBundle = inBundle;

    this.bundle_local.vars.ac = "NG_LL_model_AR_location_CMS_edit";
    this.bundle_local.vars.PROVIDER_SYSID = LOCATION_CMS[0].PROVIDER_SYSID;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      LOCATION_CMS[0].TRIGGER = 'location_cms'
      LOCATION_CMS[0].LOCATIONID = bundle_local.vars.LOCATIONID;

      bundle_local.ll_admin_generic_dg_reflectChange.emit(LOCATION_CMS);

      
    }

  }

  NG_LL_model_AR_locations_picklist_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_locations_picklist_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)    

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_LL_model_AR_locations_picklist_edit(ACTION, LOCATIONID) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_locations_picklist_edit";
    this.bundle_local.vars.LOCATIONID = LOCATIONID;
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_model_AR_payers_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.payers = arr


      if (arr.length > 0) {
        bundle_local.vars.PAYERID = arr[0].PAYERID
        bundle_local.PAYER_NAME = arr[0].FOLDERNAME
      }

      callback01(bundle_local.payers, inBundle)
     
    }
  }

  setPAYERID(PAYERID) {   
    this.bundle_local.vars.PAYERID = PAYERID;

    for (var i = 0; i < this.bundle_local.payers.length; i++) {
      if (this.bundle_local.payers[i].PAYERID == this.bundle_local.vars.PAYERID) {
        this.bundle_local.PAYER_NAME = this.bundle_local.payers[i].FOLDERNAME
        this.setPayerClass(0)
      }
    }
  }

  setPAYERID_ACTIVE(PAYERID) {
    this.bundle_local.vars.PAYERID_ACTIVE = PAYERID;
  }

  setPAYER(PAYERID) {
    this.bundle_local.vars.PAYERID = PAYERID;
    this.bundle_local.PAYER_NAME = "";

    ///console.log("PAYERID:" + this.bundle_local.vars.PAYERID)

    for (let i = 0; i < this.bundle_local.displayData.length; i++) {
      if (this.bundle_local.displayData[i].ELEMENT == PAYERID) {
        this.bundle_local.PAYER_NAME = this.bundle_local.displayData[i].LABEL
      }
    }
  }

  getPAYERID() {
    return this.bundle_local.vars.PAYERID;
  }

  getPayerName() { return this.bundle_local.PAYER_NAME }

  payer_edit_mode(PAYERID) {
    this.setPAYERID(PAYERID)
    this.ll_company_models_sp_mode.emit('editPayer')
  }

  setDIVISION(DIVISIONID) {
    this.bundle_local.vars.DIVISIONID = DIVISIONID;
    this.bundle_local.DIVISION_NAME = "";

    ///console.log("DIVISIONID:" + this.bundle_local.vars.DIVISIONID)

    for (let i = 0; i < this.bundle_local.displayData.length; i++) {
      if (this.bundle_local.displayData[i].ELEMENT == DIVISIONID) {
        this.bundle_local.DIVISION_NAME = this.bundle_local.displayData[i].LABEL
      }
    }
  }

  setDIVISIONID_ACTIVE(DIVISIONID) {
    this.bundle_local.vars.DIVISIONID_ACTIVE = DIVISIONID;
  }

  getDivisionName() { return this.bundle_local.DIVISION_NAME }

  setREGION(REGIONID) {
    this.bundle_local.vars.REGIONID = REGIONID;
    this.bundle_local.REGION_NAME = "";

    ///console.log("REGIONID:" + this.bundle_local.vars.REGIONID)

    for (let i = 0; i < this.bundle_local.displayData.length; i++) {
      if (this.bundle_local.displayData[i].ELEMENT == REGIONID) {
        this.bundle_local.REGION_NAME = this.bundle_local.displayData[i].LABEL
      }
    }
  }

  setREGIONID_ACTIVE(REGIONID) {
    this.bundle_local.vars.REGIONID_ACTIVE = REGIONID;
  }

  getRegionName() { return this.bundle_local.REGION_NAME }

  setSTATE(STATEID) {
    this.bundle_local.vars.STATEID = STATEID;
    this.bundle_local.STATE_NAME = "";

    ///console.log("STATEID:" + this.bundle_local.vars.STATEID)

    for (let i = 0; i < this.bundle_local.displayData.length; i++) {
      if (this.bundle_local.displayData[i].ELEMENT == STATEID) {
        this.bundle_local.STATE_NAME = this.bundle_local.displayData[i].LABEL
      }
    }
  }

  setSTATEID_ACTIVE(STATEID) {
    this.bundle_local.vars.STATEID_ACTIVE = STATEID;
  }

  getStateName() { return this.bundle_local.STATE_NAME }

  NG_LL_model_AR_payers_target_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_target_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      callback01(arr, inBundle)

    }
  }

  NG_LL_model_AR_payers_target_edit(callback01: Function, PAYER, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_target_edit";
    this.bundle_local.vars.PAYER = PAYER;
    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      PAYER[0].TRIGGER = 'payer'

      bundle_local.ll_admin_generic_dg_reflectChange.emit(PAYER);

    }
  }

  NG_LL_model_AR_payers_picklist_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_picklist_get"

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr) 

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_LL_model_AR_payers_picklist_edit(ACTION, PAYERID) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_payers_picklist_edit";
    this.bundle_local.vars.PAYERID = PAYERID;
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_reports_BBC_getData(callback01: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_reports_BBC_getData";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

     // console.log(arr)

      callback01(arr, inBundle)

    }
  }

  NG_LL_model_AR_reports_BBC_locations_get(callback01: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_BBC_locations_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.locations = arr;

      if (arr.length > 0) {
        bundle_local.vars.LOCATIONID = arr[0].LOCATIONID
      }

      if (callback01 != null) {
        callback01(arr, inBundle)
      } else {
        return arr
      }

    }
  }

  NG_LL_model_AR_reports_BBC_PDF_one(BBCELEMENTS, inBundle) {
        
    this.bundle_local.vars.ac = "NG_LL_model_AR_reports_BBC_PDF_one";
 
    this.bundle_local.vars.date_calc = inBundle.date_calc;
    this.bundle_local.vars.date_now = inBundle.date_now;
    this.bundle_local.vars.BBCELEMENTS = BBCELEMENTS;

    //const keys = Object.keys(inRecords);

    //for (const key of keys) {
      //this.bundle_local.vars[key.toUpperCase()] = inRecords[key.toUpperCase()];
    //}

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      var n = new Date()
      let nowDate = javaDate_to_YYYYMMDD(n);

      let name = nowDate + "_BBC_" + bundle_local.vars.COMPANYNAME + "_" + bundle_local.vars.MODELNAME + ".pdf"

      console.log(name)


      let b64Data = arr[0].UPLOADCONTENT;
      let mimeType = "application/pdf"
      let blob = base64ToBlob02(b64Data, mimeType, 512)

      //if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
      //if (window.navigator ) { // IE workaround
        ///window.navigator.msSaveOrOpenBlob(blob, name);

      //} else { //not IE

        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = name;
        a.click();
        URL.revokeObjectURL(objectUrl);

      //}

      function base64ToBlob02(b64Data, contentType, sliceSize) {
        b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);

          let byteNumbers = new Array(slice.length);

          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          let byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      }


    }
  }

  LlModelDisplaydataComponent_selectTab() {
    this.LlModelDisplaydataComponent_tabSelect.emit()
  }
   
  setDAY_choice(dayChoice, initial) {

    if (initial == true && this.bundle_local.vars.DAYCHOICE == '') {
      this.bundle_local.vars.DAYCHOICE = dayChoice;
    }

    if (initial == false) {
      this.bundle_local.vars.DAYCHOICE = dayChoice;
    }

    ///console.log("this.bundle_local.vars.DAYCHOICE :" + this.bundle_local.vars.DAYCHOICE)

  }

  getDAY_choice() {
    return this.bundle_local.vars.DAYCHOICE
  }

  setCOLUMN_choice(columnChoice, initial) {

    if (initial == true && this.bundle_local.vars.COLUMNCHOICE == '') {
      this.bundle_local.vars.COLUMNCHOICE = columnChoice;
    }

    if (initial == false) {
      this.bundle_local.vars.COLUMNCHOICE = columnChoice;
    }
    
  }

  getCOLUMN_choice() {
    return this.bundle_local.vars.COLUMNCHOICE
  }

  setDiveLevel(diveLevel) {
    this.bundle_local.vars.DIVELEVEL = diveLevel.toLowerCase();
  }

  getDiveLevel() {
    return this.bundle_local.vars.DIVELEVEL;
  }

  NG_LL_model_AR_displayData_dates_get(COLUMNS_OR_ALL, callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_dates_get";
    this.bundle_local.vars.COLUMNS_OR_ALL = COLUMNS_OR_ALL;// 'COLUMNS';

    //console.log("CFC: " + this.bundle_local.vars.ac)
    //console.log("daysBack: " + this.bundle_local.vars.daysBack)
    //console.log("DAYCHOICE: " + this.bundle_local.vars.DAYCHOICE)
    //console.log("COLUMNCHOICE: " + this.bundle_local.vars.COLUMNCHOICE)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      callback01(arr, inBundle)

    }
  }

  NG_LL_model_AR_displayData_get(COLUMNS_OR_ALL, callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_get";
    this.bundle_local.vars.COLUMNS_OR_ALL = COLUMNS_OR_ALL;/// 'COLUMNS';
    
    /////convert the active ids to the selected ids
    this.bundle_local.vars.LOCATIONID_ACTIVE = this.bundle_local.vars.LOCATIONID;
    this.bundle_local.vars.PAYERID_ACTIVE = this.bundle_local.vars.PAYERID;
    this.bundle_local.vars.FINANCIALCLASSID_ACTIVE = this.bundle_local.vars.FINANCIALCLASSID;
    this.bundle_local.vars.DIVISIONID_ACTIVE = this.bundle_local.vars.DIVISIONID;
    this.bundle_local.vars.REGIONID_ACTIVE = this.bundle_local.vars.REGIONID;
    this.bundle_local.vars.STATEID_ACTIVE = this.bundle_local.vars.STATEID;

    //console.log("this.bundle_local.vars.DIVELEVEL: "+this.bundle_local.vars.DIVELEVEL)

    if (this.bundle_local.vars.DIVELEVEL.toLowerCase() != "view") {
      this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_dive_get";
    }

    //console.log("this.bundle_local.vars.ac: " + this.bundle_local.vars.ac)
    console.log("this.bundle_local.vars.ELEMENT: " + this.bundle_local.vars.ELEMENT)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.displayData = arr;

      bundle_local.forms.splice(0);
      var foundForm: any = [];
      let indx = 0;

      for (let i = 0; i < arr.length; i++) {

        if (foundForm.indexOf(arr[i].FORMNAME) == -1) {
          foundForm.push(arr[i].FORMNAME);

          bundle_local.forms.push({ FORMNAME: arr[i].FORMNAME, FORMID: arr[i].FORMID, rowY: indx * 20 });

          indx++
        }
        arr[i].rowY = indx * 20;
        indx++
      }

      if (arr.length > 0) { 
        if (bundle_local.vars.DIVELEVEL.toLowerCase() == "view") {

          bundle_local.vars.ELEMENT = arr[0].ELEMENT;
          bundle_local.ELEMENTLABEL = arr[0].LABEL;

        } else if (bundle_local.vars.DIVELEVEL.toLowerCase() == "class" && bundle_local.vars.FINANCIALCLASSID == 0) {

          //bundle_local.vars.FINANCIALCLASSID_ACTIVE = arr[0].ELEMENT;
          //bundle_local.FINANCIALCLASS_NAME = arr[0].LABEL;

        } else if (bundle_local.vars.DIVELEVEL.toLowerCase() == "payer" && bundle_local.vars.PAYERID == 0) {

         // bundle_local.vars.PAYERID_ACTIVE = arr[0].ELEMENT;
          //bundle_local.PAYER_NAME = arr[0].LABEL;

        } else if (bundle_local.vars.DIVELEVEL.toLowerCase() == "location" && bundle_local.vars.LOCATIONID == 0) {

          //bundle_local.vars.LOCATIONID_ACTIVE = arr[0].ELEMENT;
          //bundle_local.LOCATION_NAME = arr[0].LABEL;
        }
      }

      callback01(arr, inBundle)
     
    }
  }

  NG_LL_model_AR_displayData_graph_one_data_get(callback01?: Function, inBundle?, obj?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_displayData_graph_one_data_get";
    this.bundle_local.vars.COLUMNS_OR_ALL = 'ALL';

    //console.log("this.bundle_local.vars.ac: " + this.bundle_local.vars.ac)

    let defaultId = this.bundle_local.displayData[0].ELEMENT;

    if (this.bundle_local.vars.DIVELEVEL.toLowerCase() == "class" && this.bundle_local.vars.FINANCIALCLASSID_ACTIVE == 0) {
      this.bundle_local.vars.FINANCIALCLASSID_ACTIVE = defaultId;
    } else if (this.bundle_local.vars.DIVELEVEL.toLowerCase() == "payer" && this.bundle_local.vars.PAYERID_ACTIVE == 0) {
      this.bundle_local.vars.PAYERID_ACTIVE = defaultId;
    } else if (this.bundle_local.vars.DIVELEVEL.toLowerCase() == "location" && this.bundle_local.vars.LOCATIONID_ACTIVE == 0) {
      this.bundle_local.vars.LOCATIONID_ACTIVE = defaultId;
    } else if (this.bundle_local.vars.DIVELEVEL.toLowerCase() == "division" && this.bundle_local.vars.DIVISIONID_ACTIVE == 0) {
      this.bundle_local.vars.DIVISIONID_ACTIVE = defaultId;
    } else if (this.bundle_local.vars.DIVELEVEL.toLowerCase() == "region" && this.bundle_local.vars.REGIONID_ACTIVE == 0) {
      this.bundle_local.vars.REGIONID_ACTIVE = defaultId;
    } else if (this.bundle_local.vars.DIVELEVEL.toLowerCase() == "state" && this.bundle_local.vars.STATEID_ACTIVE == 0) {
      this.bundle_local.vars.STATEID_ACTIVE = defaultId;
    }

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");
    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      callback01(arr, inBundle, obj);

    }
  }

  NG_LL_model_AR_formElements_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_formElements_get";
    this.bundle_local.vars.DIVELEVEL = inBundle.diveLevel;

    //console.log("inBundle.REPORTFORMCONTEXTID :" + inBundle.REPORTFORMCONTEXTID)

    if (inBundle.REPORTFORMCONTEXTID < 0) {
      this.bundle_local.vars.ac = "NG_LL_model_AR_formElements_dive_get";
    }

    //console.log("CFC: " + this.bundle_local.vars.ac)
    //console.log("DIVELEVEL: " + this.bundle_local.vars.DIVELEVEL)
    //console.log("PAYERID: " + this.bundle_local.vars.PAYERID)
    //console.log("LOCATIONID: " + this.bundle_local.vars.LOCATIONID)
    //console.log("FINANCIALCLASSID: " + this.bundle_local.vars.FINANCIALCLASSID)
    //console.log("REPORTFORMCONTEXTID: " + this.bundle_local.vars.REPORTFORMCONTEXTID)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

  

      if (callback01 != null) {
       callback01(bundle_local.elements, inBundle)
      } else {
       return bundle_local.elements
      }
    }
  }

  getForms() {
    return this.bundle_local.forms
  }

  getActiveElement(callback01?: Function, inBundle?) {

    if (callback01 != null) {
      callback01(this.bundle_local.vars.ELEMENT, inBundle)
    } else {
      return this.bundle_local.vars.ELEMENT
    }

  }

  getActiveElementLabel() {
    return this.bundle_local.ELEMENTLABEL;
  }

  getActiveElement_record() {

    for (let i = 0; i < this.bundle_local.elements.length; i++) {
      if (this.bundle_local.elements[i].ELEMENTID == this.bundle_local.vars.ELEMENTID) {
        return this.bundle_local.elements[i];
      }
    }
  }

  getActiveElementId() {
    return this.bundle_local.vars.ELEMENTID
  }

  setActiveElement_withElementId(ELEMENTID) {

    this.bundle_local.vars.ELEMENTID = ELEMENTID;

    for (let i = 0; i < this.bundle_local.elements.length; i++) {
      if (this.bundle_local.elements[i].ELEMENTID == this.bundle_local.vars.ELEMENTID) {

        this.bundle_local.vars.ELEMENT = this.bundle_local.elements[i].ELEMENT;
      }
    }

    
  }

  setActiveElement_withElement(ELEMENT) {

    console.log("setActiveElement_withElement: " + ELEMENT)

    this.bundle_local.vars.ELEMENT = ELEMENT;

  }

  setActiveDive_ElementId(DIVE_ELEMENTID) {

    this.bundle_local.vars.DIVE_ELEMENTID = DIVE_ELEMENTID;
  }

  NG_LL_model_AR_metric_graph_data_get(callback01?: Function, inBundle?, obj?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_metric_graph_data_get";
    

    //console.log("CFC: " + this.bundle_local.vars.ac)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      callback01(arr, inBundle, obj);

    }
  }

  NG_LL_model_AR_benchmarkGroupTypes_get(callback01?: Function, inBundle?) {

   var DESCRIPTION = '';

    for (let i = 0; i < this.bundle_local.elements.length; i++) {
      if (this.bundle_local.elements[i].ELEMENTID == this.bundle_local.vars.ELEMENTID) {
        DESCRIPTION = this.bundle_local.elements[i].DESCRIPTION
      }
    }

    this.bundle_local.vars.ac = "NG_LL_model_AR_benchmarkGroupTypes_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)      

      var foundForm: any = [];
      var forms: any = [];

      let indx = 0;

      for (let i = 0; i < arr.length; i++) {

        arr[i].DESCRIPTION = arr[i].DESCRIPTION + ": " + DESCRIPTION;

        if (foundForm.indexOf(arr[i].FORMNAME) == -1) {
          foundForm.push(arr[i].FORMNAME);

          forms.push({ FORMNAME: arr[i].FORMNAME, FORMID: arr[i].FORMID, rowY: indx * 20 });

          indx++
        }

        arr[i].rowY = indx * 20

        indx++
      }

      bundle_local.metricGroups = arr
           
      callback01(arr, forms, inBundle)
      
    }
  }

  getMetricGroups(callback01?: Function, bundle?, obj?) {    
    callback01(this.bundle_local.metricGroups, bundle, obj);
  }

  NG_LL_model_AR_metric_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_metric_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      // console.log(arr)

      callback01(arr, inBundle)
     
    }
  }

  NG_LL_model_AR_leadLenders_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_model_AR_leadLenders_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

     ///console.log(arr)

      callback01(arr, inBundle)

    }
  }

  setFunctionGroupName(functionGroupName) {
    this.bundle_local.functionGroupName = functionGroupName;

    if (functionGroupName.toLowerCase() != 'model total' ) {
      //this.setLOCATION(0)
      //this.setPAYER(0)
      //this.setPayerClass(0)
      //this.setDIVISION(0)
      //this.setREGION(0)
      //this.setSTATE(0)
    }

    if (functionGroupName.toLowerCase() != 'classes' || functionGroupName.toLowerCase() != 'payers') {
      this.setLOCATION(0)
      this.setPAYER(0)
      this.setPayerClass(0)
      this.setDIVISION(0)
      this.setREGION(0)
      this.setSTATE(0)
    }

    if (functionGroupName.toLowerCase() != 'locations') {
      //this.setLOCATION(0)
      this.setPAYER(0)
      this.setPayerClass(0)
      this.setDIVISION(0)
      this.setREGION(0)
      this.setSTATE(0)
    }


  }

  getFunctionGroupName() {
    return this.bundle_local.functionGroupName;
  }

  setExportFileName(address) {
    this.bundle_local.exportFileName = address;
  }

  getExportFileName() {
    return this.bundle_local.exportFileName;
  }

  exportCSVReport(bundle, dates, forms, records) {

    let address = bundle.LlModelsService.getExportFileName();

    //console.log(address)

    let activeModel = bundle.LlModelsService.getActiveLoanModel();
    let model = activeModel.FOLDERNAME;

    var n = new Date()
    let nowDate = javaDate_to_YYYYMMDD(n);
    let fileName = nowDate + "_" + model 
        
    if (this.bundle_local.FINANCIALCLASS_NAME != "") {
      fileName = fileName + "_" + this.bundle_local.FINANCIALCLASS_NAME;
    }
    
    if (this.bundle_local.PAYER_NAME != "") {
      fileName = fileName + "_" + this.bundle_local.PAYER_NAME;
    }

    if (this.bundle_local.LOCATION_NAME != "") {
      fileName = fileName + "_" + this.bundle_local.LOCATION_NAME;
    }

    if (this.bundle_local.DIVISION_NAME != "") {
      fileName = fileName + "_" + this.bundle_local.DIVISION_NAME;
    }

    if (this.bundle_local.REGION_NAME != "") {
      fileName = fileName + "_" + this.bundle_local.REGION_NAME;
    }

    if (this.bundle_local.STATE_NAME != "") {
      fileName = fileName + "_" + this.bundle_local.STATE_NAME;
    }

    fileName = fileName + "_" + address

    let csv = fileName + '\n';

    //add date row
    csv += 'Date,';

    for (let j = 0; j < dates.length; j++) {

      csv += dates[j].FORMAT_US

      if (j < dates.length - 1) {
        csv += ','
      } else {
        csv = csv + '\n'
      }

    }

    for (let i = 0; i < forms.length; i++) {

      csv += forms[i].FORMNAME + '\n';

      ///loop through each RECORD
      for (let j = 0; j < records.length; j++) {

        //filter on the formID
        if (forms[i].FORMID == records[i].FORMID) {

          ///add the element label to the first column
          csv += records[j].LABEL + ',';

          ///loop through dates
          for (let n = 0; n < dates.length; n++) {

            let val = removeNumberCommas(String(records[j][dates[n].COLUMNNAME]));

            csv += val;

            ///continue to add columns if there are remaining records  .. elase add a new line
            if (n < dates.length - 1) {
              csv += ','
            } else {
              csv = csv + '\n'
            }
          }
        }
      }
    }

    //console.log(csv)

    var a = document.createElement("a");
    document.body.appendChild(a);
    let blob = new Blob([csv], { type: "text/csv" }),

      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + ".csv";///'test.csv';//fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }


}
