
<div #mainDiv_ll_info_request_dashboard>


    <jqxPanel [style.top.px]="bundle.spY"
              [style.left.px]="bundle.spX"
              style="position: absolute; background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <div *ngFor="let record of bundle.records; let i = index" style="white-space: nowrap; padding-left: 20px">

        <div [style.left.px]="0"
             [style.top.px]="10 +(i * (bundle.rowH))"
             [style.height.px]="bundle.rowH"
             [style.width.px]="bundle.spW - 20"
             style="position: absolute;">

          <ll-info-request-dashboard-graph id={{record.STUDYQUESTIONID}}></ll-info-request-dashboard-graph>

        </div>


      </div>

  </jqxPanel>



</div>
